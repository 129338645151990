import React, { useEffect, useState } from "react";
import { Course, Session } from "../../context/CourseContext";
import SessionList from "./SessionList";

interface CourseListProps {
  courses: Course[];
  activeCourse: Course | null;
  activeSession: Session | null;
  setActiveCourse: (course: Course | null) => void;
  setActiveSession: (session: Session | null) => void;
  refreshCourses: () => void;
}

const CourseList: React.FC<CourseListProps> = ({
  courses,
  activeCourse,
  activeSession,
  setActiveCourse,
  setActiveSession,
  refreshCourses,
}) => {
  const [hoveredCourse, setHoveredCourse] = useState<Course | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAllCourses, setShowAllCourses] = useState<boolean>(true);

  // Load courses on mount
  useEffect(() => {
    const loadCourses = async () => {
      setIsLoading(true);
      await refreshCourses();
      setIsLoading(false);
    };
    loadCourses();
  }, []);

  // Automatically set the first course as active if none is selected
  useEffect(() => {
    if (courses.length > 0 && !activeCourse) {
      setActiveCourse(courses[0]);
    }
  }, [courses, activeCourse, setActiveCourse]);

  // Dynamic logic to toggle `showAllCourses` every 5 seconds
useEffect(() => {
  const interval = setInterval(() => {
    const shouldShowAll = !activeSession || hoveredCourse ? true : false; // Ensure this resolves to a boolean
    setShowAllCourses(shouldShowAll); // Now correctly a boolean
  }, 3000);

  return () => clearInterval(interval); // Clean up interval on unmount
}, [activeSession, hoveredCourse]); // Dependencies to recalculate


  // Handle toggling of courses and sessions
  const handleToggleCourse = (course: Course) =>
    setActiveCourse(activeCourse?.id === course.id ? null : course);

  const handleToggleSession = (session: Session | null) =>
    setActiveSession(activeSession?.id === session?.id ? null : session);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      {/* Toggle button */}
      {!showAllCourses && (
        <button
          onClick={() => setShowAllCourses(!showAllCourses)}
          className="py-2 px-4 rounded-full bg-primary text-white font-medium transition-all duration-300 hover:bg-secondary"
        >
          Show All Courses
        </button>
      )}

      {/* Courses Grid */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {courses.map((course) => {
          if (!showAllCourses && activeCourse?.id !== course.id) {
            return null;
          }

          const isActive = activeCourse?.id === course.id || hoveredCourse?.id === course.id;

          return (
            <div
              key={course.id}
              className={`relative bg-white py-6 px-6 rounded-3xl shadow-lg transform transition-all duration-500 ease-out group ${
                isActive ? "scale-105" : "scale-90 max-h-[300px]"
              }`}
              onMouseEnter={() => setHoveredCourse(course)}
              onMouseLeave={() => setHoveredCourse(null)}
            >
              {/* Course Badge */}
              <div
                className={`absolute rounded-full py-3 px-3 shadow-md transition-all duration-500 left-4 -top-6 ${
                  isActive ? "opacity-100 bg-accent text-white" : "opacity-0"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 11a3 3 3 3 3 0 110-6 3 3 0 016 0z"
                  />
                </svg>
              </div>

              {/* Course Content */}
              <div className={`mt-4 text-center transition-all duration-500 ${isActive ? "text-black" : "text-textMuted"}`}>
                <h3 className="text-lg font-semibold mb-2">{course.title}</h3>
                <p className={`text-sm transition-opacity duration-700 ${isActive ? "opacity-100" : "opacity-0"}`}>
                  {course.description}
                </p>
              </div>

              {/* Toggle Button */}
              <button
                onClick={() => handleToggleCourse(course)}
                className={`mt-4 py-2 px-4 rounded-full w-full text-sm font-medium transition-all duration-300 ${
                  isActive && (course.id === activeCourse?.id) ? "bg-gray-200 text-gray-800" : "bg-accent text-white"
                }`}
              >
                {isActive && (course.id === activeCourse?.id) ? "Deactivate" : "Activate"}
              </button>

              {/* Session List */}
              {activeCourse?.id === course.id && (
                <div className="mt-4 transition-all duration-500 opacity-100">
                  <SessionList
                    sessions={course.sessions}
                    activeSession={activeSession}
                    setActiveSession={handleToggleSession}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Refresh Button */}
      {courses.length <= 0 && !isLoading && (
        <div className="mt-6 text-center">
          <button
            onClick={async () => {
              setIsLoading(true);
              await refreshCourses();
              setIsLoading(false);
            }}
            className="py-2 px-4 rounded-full bg-primary text-white font-medium transition-all duration-300 hover:bg-secondary"
          >
            Refresh Courses
          </button>
        </div>
      )}

      {/* Loading Spinner */}
      {isLoading && (
        <div className="mt-6 text-center">
          <div className="w-20 h-20 border-4 border-t-4 border-error rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default CourseList;
