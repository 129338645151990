import React, { useEffect } from "react";
import AppRouter from "./routes/AppRouter";
import { AuthProvider } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastContext";
import { CourseProvider } from "./context/CourseContext";
import { EssayProvider } from "./context/EssayContext";
import { ActivitiesProvider } from "./context/ActivitiesContext";
import { AskuserProvider } from "./context/AskInstructorContext";
import { initializeNotifications  } from "./initializeNotifications";

const App: React.FC = () => {
  useEffect(() => {
    // Initialize notifications with error handling
    try {
      initializeNotifications ();
    } catch (error) {
      console.error("Error initializing notifications:", error);
    }
  }, []);

  return (
    <ToastProvider>
      <CourseProvider>
        <AuthProvider>
          <EssayProvider>
            <ActivitiesProvider>
              <AskuserProvider>
                <AppRouter />
              </AskuserProvider>
            </ActivitiesProvider>
          </EssayProvider>
        </AuthProvider>
      </CourseProvider>
    </ToastProvider>
  );
};

export default App;
