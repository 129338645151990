import React, { useState, useContext } from "react";
import { Link, Route, Routes } from "react-router-dom"; // Updated for Route and Routes
import {
  Home,
  AccountCircle,
  ChevronLeft,
  ChevronRight,
  Description,
  ExitToApp,
  Refresh,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import NotificationToast from "./NotificationToast";
import { AuthContext } from "./context/AuthContext";
import ActivitiesNavbar from "./components/Common/ActivitiesNavbar";
const handleRefresh = () => {
  window.location.reload();
};
// Sidebar Component
const Sidebar: React.FC<{ isOpen: boolean; toggleSidebar: () => void }> = ({
  isOpen,
  toggleSidebar,
}) => {
  const authContext = useContext(AuthContext); // Access AuthContext
  const logout = authContext?.logout; // Destructure logout function

  return (
    <motion.aside
      className={`transition-transform duration-300 ease-in-out ${
        isOpen ? "translate-x-0" : "-translate-x-full w-[1px]"
      } bg-secondary text-textBackground h-full py-4 px-1`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex justify-between items-center mb-8">
        <button
          className="md:hidden text-textBackground hover:text-gray-300"
          onClick={toggleSidebar}
        >
          {isOpen ? <ChevronLeft /> : <ChevronRight />}
        </button>
      </div>
      {isOpen && (
        <nav>
          <ul className="space-y-6">
            {[
              { to: "/user/dashboard", icon: <Home />, label: "Home" },
              { to: "/user/profile", icon: <AccountCircle />, label: "Profile" },
              {
                to: "/user/notificationSettings/5",
                icon: <Description />,
                label: "Notification Settings", // Add label
              },
            ].map((item, index) => (
              <motion.li
                key={item.to}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, delay: 0.1 * (index + 1) }}
              >
                <Link
                  to={item.to}
                  className="flex items-center py-3 px-5 hover:bg-accent rounded-md transition duration-200"
                >
                  {item.icon}
                  {isOpen && (
                    <span className="ml-3 text-lg text-textPrimary">{item.label}</span>
                  )}
                </Link>
              </motion.li>
            ))}
            {/* Logout Button */}
            <motion.li
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <button
                onClick={logout}
                className="flex items-center w-full py-3 px-5 hover:bg-error rounded-md transition duration-200 text-error"
              >
                <ExitToApp />
                {isOpen && <span className="ml-3">Logout</span>}
              </button>
            </motion.li>
          </ul>
        </nav>
      )}
    </motion.aside>
  );
};

// Main Content Component
const MainContent: React.FC<{
  children: React.ReactNode;
  isContentVisible: boolean;
  isSidebarOpen: boolean;
  isActivitiesOpen: boolean;
  toggleContentVisibility: () => void;
  toggleActivities: () => void;
  toggleSidebar: () => void;
}> = ({
  children,
  isContentVisible,
  toggleContentVisibility,
  isSidebarOpen,
  toggleSidebar,
  isActivitiesOpen,
  toggleActivities,
}) => {
  return (
    <main
      className={`w-full justify-center overflow-auto m-auto flex bg-background h-full p-8  ${
        isContentVisible ? "" : "hidden"
      }`}
    >
      <div className="flex  justify-between items-center mb-4 relative"></div>
      {!isActivitiesOpen && <NotificationToast />}

      <motion.div
        className="rounded-lg "
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </motion.div>

      {!isSidebarOpen && (
        <button
          className="absolute top-4 left-4 p-2 text-textBackground bg-primary rounded-md "
          onClick={toggleSidebar}
        >
          <ChevronRight />
        </button>
      )}
    </main>
  );
};

// Layout Component
const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isContentVisible, setIsContentVisible] = useState<boolean>(true);
  const [isActivitiesOpen, setIsActivitiesOpen] = useState<boolean>(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleActivities = () => setIsActivitiesOpen(!isActivitiesOpen);
  const toggleContentVisibility = () => setIsContentVisible(!isContentVisible);

  return (
    <div className="flex  h-screen overflow-hidden bg-gray-100 max-sm:flex">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <MainContent
        isContentVisible={isContentVisible}
        toggleContentVisibility={toggleContentVisibility}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        isActivitiesOpen={isActivitiesOpen}
        toggleActivities={toggleActivities}
      >
        <ActivitiesNavbar isOpen={isActivitiesOpen} toggleActivities={toggleActivities} />

        {children}
           {/* Refresh Button for Mobile */}
      <button
        onClick={handleRefresh}
        className="sm:hidden fixed bottom-4 right-4 p-4 bg-primary text-textBackground rounded-full shadow-md hover:bg-accent transition duration-200"
      >
        <Refresh />
      </button>
      </MainContent>

    </div>
  );
};

export default Layout;
