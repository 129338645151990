import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Auth/Login";
import ProtectedRoute from "../pages/Auth/ProtectedRoute";
import Layout from "../Layout";
import { ToastProvider } from "../context/ToastContext"; // ToastProvider
import { BrowserRouter } from "react-router-dom"; // BrowserRouter
import UserDashboard from "../pages/User/UserDashboard";
import UserActivities from "../pages/User/UserActivities";
import UserProblems from "../pages/User/UserProblems";
import EssayContainer from "../pages/User/EssayContainer";
import UserProfile from "../pages/User/UserProfile";
import NotificationSettings from "../pages/User/NotificationSettings";

const AppRouter: React.FC = () => {
  return (
      <BrowserRouter>
        <Layout>
          <Routes>
            {/* Public Route */}
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/user/dashboard" element={<UserDashboard />} />
              <Route path="/user/activities" element={<UserActivities />} />
              <Route path="/user/problems" element={<UserProblems />} />
              <Route path="/essays" element={<EssayContainer />} />
              <Route path="/user/profile/:id" element={<UserProfile />} />
              <Route path="/user/notificationSettings/:id" element={<NotificationSettings />} />
            </Route>

            {/* Catch-all Route */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Layout>
      </BrowserRouter>
  );
};

export default AppRouter;
