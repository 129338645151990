import React, { useState, useContext, useEffect } from 'react';
import { InputField } from '../InputField';
import { AuthContext } from '../../context/AuthContext';

interface ProfileInfoFieldsProps {
  isEditing: boolean;
  formData: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageChange: (file: File | string | null) => void;
  onSubmit: (formData: FormData) => void;
}

const ProfileInfoFields: React.FC<ProfileInfoFieldsProps> = ({
  isEditing,
  formData,
  handleInputChange,
  handleImageChange,
  onSubmit,
}) => {
  const auth = useContext(AuthContext); // Access the user context
  const [image, setImage] = useState<File | null>(null); // State to store the selected image

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImage(file); // Store the selected image file in state
      handleImageChange(file); // Pass the File object to handleImageChange
    }
  };
  
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formDataWithImage = new FormData();
    formDataWithImage.append('username', formData.username);
    formDataWithImage.append('email', formData.email);
    formDataWithImage.append('phone_number', formData.phone_number);

    // Append profile image if available
    if (image instanceof File) {
      formDataWithImage.append('profile_image', image);
    }

    onSubmit(formDataWithImage); // Call the onSubmit function passed as a prop
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="flex-col gap-6">
        <div className="text-[#caced8] text-base font-bold">Personal</div>
        <div className="flex gap-3">
          <InputField
            label="Username"
            type="text"
            name="username"
            value={formData.username || ''}
            isEditing={isEditing}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex gap-3">
          <InputField
            label="First Name"
            type="text"
            name="firstName"
            value={formData.firstName || ''}
            isEditing={isEditing}
            onChange={handleInputChange}
          />
        </div>

       
        {/* Conditionally render child data if available */}
        {auth?.user?.user_info.user_type === 'parent' && 'children' in auth?.user && (
          <div className="mt-6">
            <div className="text-[#caced8] text-base font-bold">Child Info</div>
            {auth?.user?.parent?.children.map((child) => (
              <div key={child.id} className="flex flex-col gap-3">
                <div>Name: {child.name}</div>
                <div>Age: {child.age}</div>
                <div>Experience: {child.experience}</div>
              </div>
            ))}
          </div>
        )}
{/* 
        <div className="mt-6">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={!isEditing}
          >
            Save Changes
          </button>
        </div> */}
      </div>
    </form>
  );
};

export default ProfileInfoFields;
