// firebaseConfig.ts
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBjmLt5w_6LtndPjITo__LlLg3HQ-PNQ6A",
  authDomain: "elmohandes-academy-e0e53.firebaseapp.com",
  projectId: "elmohandes-academy-e0e53",
  storageBucket: "elmohandes-academy-e0e53.firebasestorage.app",
  messagingSenderId: "950163802077",
  appId: "1:950163802077:web:2a402d30834ee059f05834",
  measurementId: "G-YWMQJ05PQC"
};

export const app = initializeApp(firebaseConfig);
