import React, { useContext, useState, useEffect } from "react";
import { EssayContext, Essay } from "../../context/EssayContext";
import { AuthContext } from "../../context/AuthContext"; // Import the AuthContext
import EssayForm from "./UserEssay";
import EssayList from "./EssayList";

const EssayContainer: React.FC = () => {
  const { essays, createEssay, deleteEssay, updateEssay, fetchEssays } = useContext(EssayContext)!;
  const { user } = useContext(AuthContext)!; // Get user data from AuthContext
  const [selectedEssay, setSelectedEssay] = useState<Essay | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false); // For form success feedback

  // Handle success message timeout
  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => setIsSuccess(false), 3000); // Hide success message after 3 seconds
      return () => clearTimeout(timer); // Clean up the timeout
    }
  }, [isSuccess]);

  const handleFormSubmit = (formData: { title: string; content: string; status: string }) => {
    if (selectedEssay) {
      // Update the existing essay
      updateEssay({ ...selectedEssay, ...formData });
      setSelectedEssay(null); // Reset form after updating
      setIsSuccess(true); // Show success feedback
    } else {
      // Create a new essay using the logged-in user's ID
      createEssay({
        author: user?.user_info.user_id || 0, // Use the logged-in user's ID; fallback to 0 if user is null
        status: formData.status,
        word_count: 0, // Optional: Adjust based on actual word count logic
        title: formData.title,
        content: formData.content,
      });
      setIsSuccess(true); // Show success feedback
    }
  };

  const handleEdit = (essay: Essay) => {
    setSelectedEssay(essay); // Set the selected essay for editing
  };

  // Reset the form when selectedEssay changes
  useEffect(() => {
    if (selectedEssay) {
      setSelectedEssay(selectedEssay); // Populate the form with selected essay data
    }
  }, [selectedEssay]);

  // Refresh essays when the button is clicked
  const refreshEssays = () => {
    fetchEssays(); // Call the fetchEssays function to refresh the essays
  };

  return (
    <div className="max-w-6xl mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Manage Essays</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Form Section */}
        <div className="p-6 bg-gray-100 rounded-lg shadow-sm">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            {selectedEssay ? "Edit Essay" : "Add New Essay"}
          </h2>
          {isSuccess && (
            <div className="mb-4 p-3 bg-green-100 text-green-800 rounded-md">
              <p>Success! Your essay has been {selectedEssay ? "updated" : "added"}.</p>
            </div>
          )}
          <EssayForm onSubmit={handleFormSubmit} initialData={selectedEssay} />
        </div>

        {/* Essay List Section */}
        <div className="p-6 bg-gray-50 rounded-lg shadow-sm">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Your Essays</h2>
          <EssayList essays={essays} onDelete={deleteEssay} onEdit={handleEdit} />
          <div className="mt-4">
            {/* Refresh Button */}
            <button
              onClick={refreshEssays}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Refresh Essays
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EssayContainer;
