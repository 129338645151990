import React from "react";
import ChildAvatar from "../../components/ChildAvatar";
import { Session } from "../../context/CourseContext";
import { getFormattedStartTime } from "../../components/Common/getFormattedStartTime";
import { getSessionStatusDetails } from "../../components/Common/getSessionStatusDetails";
import { useAskuser } from "../../context/AskInstructorContext";

interface SessionDetailsHeaderProps {
  session: Session;
}

const SessionDetailsHeader: React.FC<SessionDetailsHeaderProps> = ({
  session,
}) => {
  // Format the start time using Intl.DateTimeFormat for Arabic localization

  const isGoogleDriveVideo =
    session.meeting_link && session.meeting_link.includes("drive.google");

  const {
    selectedChildId,
    selectedChildName,
    taskToAsk,
    openAskuserModal,
    closeAskuserModal,
    userRole,
  } = useAskuser();

  const handleAskuserClick = () => {
    if (taskToAsk) {
      openAskuserModal(session.id, selectedChildName, );
    } else {
      openAskuserModal(session.id, selectedChildName, );
      // Handle the case where no task is selected
      // alert("يرجى اختيار مهمة لطرحها على المدرب.");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between items-center bg-gradient-to-r from-white to-background p-6 rounded-2xl shadow-md text-right font-cairo">
      {/* Session Details */}
      <div className="flex flex-col lg:w-2/3">
        <h4 className="text-3xl font-semibold text-primary mb-4">
          {session.title}
        </h4>
        <p className="text-lg text-secondary mb-4">
          {session.description || "لا يوجد وصف لهذه المحاضرة"}
        </p>

        {/* Start Time Display */}
        <div className="text-sm text-secondary mt-4">
          <p className="font-semibold text-primary">
            الوقت الفعلي للبدء:
          </p>
          <p className="text-lg text-accent font-bold">
            {getFormattedStartTime(session)}
          </p>
        </div>
        {/* Start Time Display */}
        <div className="text-sm text-secondary mt-4">
          <p className="font-semibold text-primary">
            تفاصيل اكثر
          </p>
          <p className="text-lg text-accent font-bold">
            {getSessionStatusDetails(session)}
          </p>
        </div>

        {/* Meeting Link or Video */}
        <div className="mt-4">
          {isGoogleDriveVideo ? (
            <a
              href={session.meeting_link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-6 py-2 bg-primary text-white font-semibold rounded-lg shadow-md transition-transform transform hover:scale-105"
            >
              شاهد الفيديو
            </a>
          ) : (
            <a
              href={session.meeting_link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary underline"
            >
              انضم إلى الاجتماع
            </a>
          )}
        </div>

        {/* Ask user Button */}
        <button
          onClick={handleAskuserClick}
          className="mt-4 px-6 py-2 bg-accent text-white font-semibold rounded-lg shadow-md transition-transform transform hover:scale-105"
        >
          اسأل المدرب
        </button>
      </div>

      {/* Child Avatars */}
      <div className="flex flex-wrap lg:w-1/3 mt-6 lg:mt-0 justify-center lg:justify-end">

        <ChildAvatar />
      </div>
    </div>
  );
};

export default SessionDetailsHeader;
