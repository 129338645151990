import React, { useState } from "react";
import {
  ChildInCourse,
  Course,
  Session,
} from "../../context/CourseContext";
import SessionDetailsHeader from "./SessionDetailsHeader";
import { useAskuser } from "../../context/AskInstructorContext";
import AskuserModal from "../../components/Common/AskInstructorModal";

interface Task {
  name: string;
  is_alive: boolean;
  description: string;
  sessionId: number;
}

interface SessionDetailsProps {
  session: Session;
  course: Course;
  activeSessionChildren: ChildInCourse[];
  activeSessionChildrenAttendance: ChildInCourse[];
  activeSessionChildrenAbsent: ChildInCourse[];
  handleToggleSession: (
    session: Session,
    courseId: number,
    isEnded: boolean
  ) => void;
  handleAddChildAction: (child: number, sessionId: number) => void;
  handleAddTaskToSession: (newTask: Task) => void;
  postProblem: (sessionId: number, problemDescription: string) => Promise<void>;
}

const SessionDetails: React.FC<SessionDetailsProps> = ({
  session,
  course,
  activeSessionChildrenAttendance,
  activeSessionChildrenAbsent,
  handleToggleSession,
  handleAddChildAction,
  handleAddTaskToSession,
  postProblem,
}) => {
  const [meetingLink, setMeetingLink] = useState(session.meeting_link);
  const [isEditingLink, setIsEditingLink] = useState(false);

  const {
    showAskuserModal,
    taskToAsk,
    selectedChildId,
    selectedChildName,
    openAskuserModal,
    closeAskuserModal,
  } = useAskuser();

  const motivationalPhrases = [
    "أنت تستطيع تحقيق أي شيء إذا وضعت هدفك أمام عينيك!",
    "كل مهمة تنجزها هي خطوة جديدة نحو بناء مستقبلك المشرق.",
    "تذكر دائمًا أن الاجتهاد اليوم هو المفتاح لإنجازات الغد.",
    "المهام ليست مجرد واجب، بل هي فرصة لاكتشاف قدراتك وتنمية مهاراتك.",
    "كلما أنجزت مهمة، اقتربت أكثر من تحقيق أحلامك.",
    "التحديات الصغيرة اليوم تصنع قادة المستقبل.",
    "إنجاز المهام هو دليل على قوتك وإصرارك.",
    "كل جهد تبذله الآن هو استثمار في مستقبلك.",
    "النجاح يبدأ بخطوات صغيرة. اجعل هذه المهام أولى خطواتك نحو تحقيق أحلامك.",
    "ثق بنفسك وابدأ! كل مهمة تُنجزها تُقربك من هدفك.",
  ];

  const randomMotivationalPhrase =
    motivationalPhrases[Math.floor(Math.random() * motivationalPhrases.length)];

  const handleSaveMeetingLink = () => {
    session.meeting_link = meetingLink;
    handleToggleSession(session, course.id, session.is_ended);
    setIsEditingLink(false);
  };

  return (
    <div className="session-details p-8 rounded-3xl shadow-lg bg-gradient-to-br from-background to-white mt-6 transition-transform transform hover:scale-105 hover:shadow-2xl font-cairo">
      <SessionDetailsHeader session={session} />

      <div className="mt-6 space-y-6" dir="rtl">
        <div>
          <h3 className="text-xl font-bold text-primary">مهام الجلسة</h3>
        </div>

        {/* العبارة التحفيزية */}
        <div className="mt-4 p-4 bg-secondary text-white text-center rounded-lg">
          {randomMotivationalPhrase}
        </div>
      </div>

      {showAskuserModal && selectedChildId && (
        <AskuserModal
          showModal={showAskuserModal}
          activeSession={session}
          handleModalClose={closeAskuserModal}
          taskToAsk={taskToAsk}
          child_id={selectedChildId}
          child={selectedChildName}
          userRole="user"
        />
      )}
    </div>
  );
};

export default SessionDetails;
