import React, { useEffect, useState } from "react";
import { useToast } from "./context/ToastContext";

const ToastContainer: React.FC = () => {
  const { toasts, removeToast } = useToast();
  const [timers, setTimers] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    // Automatically remove toast after 3 seconds and manage the timer progress
    const timeoutIds: NodeJS.Timeout[] = [];

    toasts.forEach((toast) => {
      const timeoutId = setTimeout(() => {
        removeToast(toast.id); // Remove the toast after 3 seconds
      }, 3000); // Adjust to your desired toast duration (e.g., 3000ms)

      timeoutIds.push(timeoutId);
    });

    return () => {
      timeoutIds.forEach((id) => clearTimeout(id)); // Clear timeouts on unmount
    };
  }, [toasts, removeToast]);

  useEffect(() => {
    const timerIntervals: NodeJS.Timeout[] = [];

    toasts.forEach((toast) => {
      const intervalId = setInterval(() => {
        setTimers((prevTimers) => ({
          ...prevTimers,
          [toast.id]: (prevTimers[toast.id] || 0) + 1,
        }));
      }, 100); // Update every 100ms for smoother animation

      timerIntervals.push(intervalId);
    });

    // Cleanup intervals on unmount
    return () => {
      timerIntervals.forEach((id) => clearInterval(id));
    };
  }, [toasts]);

  return (
    <div className="fixed top-5 right-5 z-50">
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`p-4 mb-2 text-white rounded-lg transition-transform transform w-fit relative ${
            toast.type === "success"
              ? "bg-green-500"
              : toast.type === "error"
              ? "bg-red-500"
              : toast.type === "info"
              ? "bg-blue-500"
              : "bg-yellow-500"
          }`}
        >
          <div
            className="absolute top-0 left-0 right-0 h-1 bg-white opacity-30"
            style={{
              width: `${(timers[toast.id] || 0) * 100 / 30}%`, // 30 is the total timer duration in 100ms intervals
              transition: "width 0.1s linear",
            }}
          ></div>
          {toast.message}
          <button
            onClick={() => removeToast(toast.id)}
            className="ml-4 text-xl font-semibold text-white"
          >
            ×
          </button>
        </div>
      ))}
    </div>
  );
};

export default ToastContainer;
