// UserProfile.tsx
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext, User } from "../../context/AuthContext";
import ProfileCard from "./UserHeader";
import TabNavigation from "../../components/Common/TabNavigation";
import ProfileInfoFields from "../../components/Common/ProfileInfoFields";
import { InputField } from "../../components/InputField";
import ProfileImageUpload from "../../components/Common/ProfileImageUpload";
import UploadProgressBar from "../../components/Common/UploadProgressBar";

const UserProfile: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const [userData, setUserData] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<File | string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [activeTab, setActiveTab] = useState("personal");
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  useEffect(() => {
    const fetchUserData = async () => {
      if (authContext?.user) {
        const user = authContext.user;
        if (user.user_info.user_id.toString() === id) {
          setUserData(user);
          setImage(user.user_info.profile_image || "");
          setFormData({
            username: user.user_info.username,
            email: user.user_info.email,
            phone_number: user.user_info.phone_number,
            profile_image: user.user_info.profile_image || "",
          });
          setLoading(false);
        } else {
          console.error("Unauthorized access or invalid user ID.");
          navigate("/error");
        }
      } else {
        console.error("Auth context or user is not available.");
        navigate("/error");
      }
    };

    fetchUserData();
  }, [authContext, id, navigate]);
  useEffect(() => {
    if (activeTab === "editAll") {
      setIsEditing(true);
    }
  }, [activeTab]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (file: File | string | null) => {
    setImage(file);
  };

  const toggleEditMode = () => setIsEditing(!isEditing);

  const handleProfileUpdate = async () => {
    const formDataWithImage = new FormData();
    formDataWithImage.append("username", formData.username);
    formDataWithImage.append("email", formData.email);
    formDataWithImage.append("phone_number", formData.phone_number);
    formDataWithImage.append("profile_image", formData.profile_image);

    if (image instanceof File) formDataWithImage.append("profile_image", image);

    try {
      if (authContext && id) {
        const response: any = await authContext.updateUser(
          parseInt(id),
          formDataWithImage,
          (progress: number) => {
            setUploadProgress(progress);
          }
        );
        if (response !== undefined) {
          setImage(response?.profile_image || "");
          setUserData({
            ...userData!,
            user_info: response,
          });
        }
        setIsEditing(false);
        setUploadProgress(0);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  if (loading)
    return (
      <div className="flex justify-center items-center">
        <div className="spinner animate-spin rounded-full border-t-2 border-b-2 border-blue-500 w-16 h-16"></div>
      </div>
    );

  return (
    <div className="flex flex-col justify-start items-start gap-16">
      <ProfileCard
        profile_image={typeof image === "string" ? image : ""}
        iconUrl={typeof image === "string" ? image : ""}
        name={authContext?.user?.child?.name}
        description="user"
      />

      <div className="h-[543px] gap-4 grid grid-cols-8">
        <TabNavigation activeTab={activeTab} onTabChange={setActiveTab} />
        <div className="grow shrink basis-0 flex-col gap-10 col-span-6">
          <div className="self-stretch flex justify-between items-center">
            <div className="text-[#caced8] text-2xl font-bold">
              {activeTab === "personal"
                ? "Edit Profile"
                : activeTab === "contact"
                ? "Contact Information"
                : "Edit All Information"}
            </div>
            <div className="text-right text-[#caced8] text-base font-bold">
              Last update August 1
            </div>
          </div>

          {activeTab === "personal" && (
            <ProfileInfoFields
              handleImageChange={handleImageChange}
              isEditing={isEditing}
              formData={formData}
              onSubmit={formData}
              handleInputChange={handleInputChange}
            />
          )}
          {activeTab === "contact" && (
            <div className="flex-col gap-6">
              <div className="text-[#caced8] text-base font-bold">Contact</div>
              <div className="self-stretch gap-6 flex">
                <InputField
                  label="Email"
                  type="email"
                  name="email"
                  value={formData.email || ""}
                  isEditing={isEditing}
                  onChange={handleInputChange}
                />
                <InputField
                  label="Phone Number"
                  type="tel"
                  name="phone_number"
                  value={formData.phone_number || ""}
                  isEditing={isEditing}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}
          {activeTab === "editAll" && (
            <div className="flex gap-8 flex-col">
              <ProfileImageUpload
                image={image}
                handleImageChange={handleImageChange}
              />
              <ProfileInfoFields
                onSubmit={formData}
                isEditing={isEditing}
                handleImageChange={handleImageChange}
                formData={formData}
                handleInputChange={handleInputChange}
              />
              <InputField
                label="Phone Number"
                type="tel"
                name="phone_number"
                value={formData.phone_number || ""}
                isEditing={isEditing}
                onChange={handleInputChange}
              />
              <InputField
                label="Email"
                type="email"
                name="email"
                value={formData.email || ""}
                isEditing={isEditing}
                onChange={handleInputChange}
              />
              {isEditing && (
                <button
                  onClick={handleProfileUpdate}
                  className="btn-primary bg-primary px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-200"
                >
                  Save Changes
                </button>
              )}
            </div>
          )}

          {uploadProgress > 0 && uploadProgress < 100 && (
            <UploadProgressBar progress={uploadProgress} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
