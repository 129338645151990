import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBjmLt5w_6LtndPjITo__LlLg3HQ-PNQ6A",
  authDomain: "elmohandes-academy-e0e53.firebaseapp.com",
  projectId: "elmohandes-academy-e0e53",
  storageBucket: "elmohandes-academy-e0e53.firebasestorage.app",
  messagingSenderId: "950163802077",
  appId: "1:950163802077:web:2a402d30834ee059f05834",
  measurementId: "G-YWMQJ05PQC",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase messaging
const messaging = getMessaging(app);

export const initializeNotifications = async (): Promise<void> => {
  try {
    // Register the service worker
    const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");

    // Request notification permission
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      window.alert("Notifications enabled!");

      // Get the FCM token
      const currentToken = await getToken(messaging, {
        vapidKey: "BFLF5luNDnu_jd-GrUpHnvhzGViZwxA5HPCXDqvXHK-J2bLT0pPoNaSZnJsxY9cxnTTivLUHEEYXVHhSWQxg9pI",
        serviceWorkerRegistration: registration,
      });

      if (currentToken) {
        console.log("FCM Token:", currentToken);
        const user = localStorage.getItem("user");
        if (user) {
          try {
            const parsedUser = JSON.parse(user);
            const userId = parsedUser.id;

            await fetch("https://codeoceantech.pythonanywhere.com/api/notifications/token/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${parsedUser.token.access}`,
              },
              body: JSON.stringify({
                userId,
                fcm_token: currentToken,
              }),
            });

            console.log("FCM token sent to backend successfully!");
            window.alert("Notification token registered with the server.");
          } catch (error) {
            console.error("Error sending FCM token to backend:", error);
            window.alert("Failed to register notification token with the server.");
          }
        } else {
          console.log("User is not logged in, skipping token update.");
          window.alert("User not logged in. Notifications are active but not tied to a user.");
        }
      } else {
        console.error("No registration token available.");
        window.alert("Failed to generate notification token.");
      }
    } else {
      console.warn("Notification permission denied.");
      window.alert("Notifications permission denied. Please enable it in your browser settings.");
    }

    // Listen for foreground messages
    onMessage(messaging, (payload) => {
      console.log("Message received in foreground:", payload);
      window.alert(`New Notification: ${payload.notification?.title}`);
    });
  } catch (error) {
    console.error("Error initializing notifications:", error);
    window.alert("An error occurred while initializing notifications. Check the console for details.");
  }
};
