import React from "react";
import CourseSessionPreview from "../Courses/CourseSessionPreview";

const UserDashboard: React.FC = () => {
  return (
    <div className="min-h-screen bg-background text-primary p-8">
      {/* Include the CourseSessionPreview Component */}
      <div className="mt-6">
        <CourseSessionPreview />
      </div>
    </div>
  );
};

export default UserDashboard;
