import React from "react";

interface InputProps {
  label: string;
  type: string;
  name: string;
  value: string;
  isEditing: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputField: React.FC<InputProps> = ({
  label,
  type,
  name,
  value,
  isEditing,
  onChange,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "tel") {
      // Restrict input to valid phone number characters (digits, "+" for international format)
      const phoneRegex = /^[\d+]*$/;
      if (phoneRegex.test(e.target.value)) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  return (
    <div className="mb-6 animate-fadeIn transition duration-300">
      <label
        htmlFor={name}
        className="block text-lg font-semibold text-gray-700 mb-2"
      >
        {label}:
      </label>

      {isEditing ? (
        <>
          {type === "file" ? (
            <div className="relative group">
              <input
                type="file"
                name={name}
                id={name}
                onChange={handleInputChange}
                className="hidden"
              />
              <label
                htmlFor={name}
                className="flex items-center justify-center w-full px-4 py-3 border border-dashed border-gray-400 rounded-md cursor-pointer bg-gray-50 text-gray-600 hover:bg-gray-100 hover:border-blue-500 hover:text-blue-500 transition duration-300 ease-in-out"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16l4 4m0 0l4-4m-4 4V4m13-1a2 2 0 00-2-2H6a2 2 0 00-2 2v18a2 2 0 002 2h10a2 2 0 002-2V9l-5-5z"
                  />
                </svg>
                Upload File
              </label>
              <span className="block text-sm text-gray-500 mt-2">
                {value ? `Selected: ${value}` : "No file selected"}
              </span>
            </div>
          ) : (
            <input
              type={type}
              name={name}
              id={name}
              value={value}
              onChange={handleInputChange}
              pattern={type === "tel" ? "[+0-9]*" : undefined}
              placeholder={
                type === "tel" ? "Enter phone number (e.g., +123456789)" : ""
              }
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ease-in-out transform hover:scale-105"
            />
          )}
        </>
      ) : (
        <p className="text-lg text-gray-800">{value || "-"}</p>
      )}
    </div>
  );
};
