import React, { useState } from 'react';

interface ImageUploadProps {
  initialImage: string | File | null;
  onImageChange: (image: File | string | null) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ initialImage, onImageChange }) => {
  const [image, setImage] = useState<File | string | null>(initialImage);
  const [isEditing, setIsEditing] = useState(false);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      onImageChange(file);
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleRemoveImage = () => {
    setImage(null);
    onImageChange(null);
    setIsEditing(false);
  };

  return (
    <div className="relative">
      {image && typeof image !== 'string' ? (
        <div className="w-32 h-32 rounded-full overflow-hidden mb-4 relative">
          <img src={URL.createObjectURL(image)} alt="Preview" className="w-full h-full object-cover" />
          {isEditing && (
            <button
              onClick={handleRemoveImage}
              className="absolute top-0 right-0 bg-red-600 text-white p-2 rounded-full hover:bg-red-700 transition-colors duration-300"
            >
              &times;
            </button>
          )}
        </div>
      ) : image && typeof image === 'string' ? (
        <div className="w-32 h-32 rounded-full overflow-hidden mb-4 relative">
          <img src={image} alt="Preview" className="w-full h-full object-cover" />
          {isEditing && (
            <button
              onClick={handleRemoveImage}
              className="absolute top-0 right-0 bg-red-600 text-white p-2 rounded-full hover:bg-red-700 transition-colors duration-300"
            >
              &times;
            </button>
          )}
        </div>
      ) : (
        <div className="w-32 h-32 rounded-full bg-gray-300 mb-4 flex justify-center items-center">
          <span className="text-white">No Image</span>
        </div>
      )}

      <div className="flex items-center gap-4">
        <label
          htmlFor="image-upload"
          className="btn-primary cursor-pointer flex justify-center items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
        >
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
          />
          Upload Image
        </label>
        {image && !isEditing && (
          <button
            onClick={handleEditClick}
            className="text-blue-500 font-bold hover:underline transition duration-300"
          >
            Edit
          </button>
        )}
      </div>

      {isEditing && (
        <div className="mt-4">
          <button
            onClick={handleRemoveImage}
            className="w-full bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition duration-300"
          >
            Remove Image
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
