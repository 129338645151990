import React, { useContext } from "react";
import { CourseContext, Session } from "../context/CourseContext";
import { motion } from "framer-motion";

const ChildAvatarList: React.FC = () => {
  const courseContext = useContext(CourseContext);

  const getTotalPossibleDegreeInSession = (session: Session): number => {
    let totalPossibleDegree = 0;
    const { activeSessionChildrenTasks } = courseContext || {};

    activeSessionChildrenTasks?.forEach((task) => {
      task.children?.forEach(() => {
        const taskMaxDegree = 100; // Replace with real max degree if needed
        totalPossibleDegree += taskMaxDegree;
      });
    });

    return totalPossibleDegree;
  };

  const getTotalDegreeForChildInSession = (
    targetChild: any,
    session: Session
  ): number => {
    let totalDegree = 0;
    const { activeSessionChildrenTasks } = courseContext || {};

    activeSessionChildrenTasks?.forEach((task) => {
      task.children?.forEach((child) => {
        if (child.id === targetChild.id) {
          totalDegree += child.degree || 0;
        }
      });
    });

    return totalDegree;
  };

  const getPercentageForChildInSession = (
    targetChild: any,
    session: Session
  ): number => {
    const totalDegree = getTotalDegreeForChildInSession(targetChild, session);
    const totalPossibleDegree = getTotalPossibleDegreeInSession(session);

    if (totalPossibleDegree === 0) return 0; // Avoid division by zero
    return (totalDegree / totalPossibleDegree) * 100;
  };

  if (!courseContext) {
    return (
      <div className="text-red-500">
        Error: CourseContext is not defined. Please check the provider setup.
      </div>
    );
  }

  const {
    activeSessionChildren,
    activeSessionChildrenAbsent,
    activeSessionChildrenAttendance,
    activeSession,
  } = courseContext;

  const percentages = activeSessionChildren.map((child) => ({
    child,
    percentage: getPercentageForChildInSession(child, activeSession!),
  }));

  const sortedByPercentage = percentages.sort(
    (a, b) => b.percentage - a.percentage
  );

  let rank = 1;
  const rankedChildren = sortedByPercentage.map((current, index, array) => {
    if (index > 0 && current.percentage < array[index - 1].percentage) {
      rank = index + 1;
    }
    return { ...current, rank };
  });

  return (
    <div className="flex flex-wrap overflow-auto relative">
      {rankedChildren.map(({ child, percentage, rank }) => {
        const isInAttendance = activeSessionChildrenAttendance?.some(
          (c) => c.id === child.id
        );
        const isAbsent = activeSessionChildrenAbsent?.some(
          (c) => c.id === child.id
        );

        const borderColor = isInAttendance
          ? "border-green-500"
          : isAbsent
          ? "border-red-500"
          : "border-gray-300";

        const bgColor = isInAttendance
          ? "bg-green-100"
          : isAbsent
          ? "bg-red-100"
          : "bg-gray-200";

        const rankIcon =
          rank === 1 ? "🥇" : rank === 2 ? "🥈" : rank === 3 ? "🥉" : null;

        return (
          <motion.div
            key={child.id}
            className="flex flex-col items-center m-2 cursor-pointer relative"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", stiffness: 100 }}
            // onClick={() => handleAvatarClick(child)} // Handle click to open modal
          >
            {activeSession?.get_tasks &&
              activeSession?.get_tasks.length > 0 && (
                <div className="absolute -top-3 -left-3 text-xl">
                  {rankIcon ? (
                    <span role="img" aria-label={`Rank ${rank}`}>
                      {rankIcon}
                    </span>
                  ) : (
                    activeSession &&
                    getTotalDegreeForChildInSession(child, activeSession) >
                      0 && (
                      <span className="font-bold text-gray-700">{`#${rank}`}</span>
                    )
                  )}
                </div>
              )}
            {child.avatar_url ? (
              <img
                src={child.avatar_url}
                alt={child.name || "Child"}
                className={`w-12 h-12 rounded-full object-cover border-2 ${borderColor} shadow-md`}
              />
            ) : (
              <div
                className={`w-12 h-12 rounded-full flex items-center justify-center ${bgColor} border-2 ${borderColor} shadow-md`}
              >
                <span className="text-lg font-medium text-gray-600">
                  {child.name ? child.name.charAt(0).toUpperCase() : "?"}
                </span>
              </div>
            )}
            <span className="text-sm mt-1 mb-1 w-[100px] text-center">
              {child.name || "Unnamed"}
            </span>
            {activeSession?.get_tasks && activeSession?.get_tasks.length > 0 ? (
              <>
                <span className="text-xs text-gray-500 mt-1">
                  Percentage: {percentage.toFixed(2)}%
                </span>
                <span className="text-xs text-gray-500 mt-1">
                  Degree:{" "}
                  {activeSession &&
                    getTotalDegreeForChildInSession(child, activeSession)}
                </span>
              </>
            ) : (
              // Adding a spinner animation for loading state
              <div className="flex justify-center items-center mt-1 mb-1 w-[100px]">
                <div className="w-5 h-5 border-4 border-t-transparent border-gray-300 rounded-full animate-spin"></div>
              </div>
            )}
          </motion.div>
        );
      })}
    </div>
  );
};

export default ChildAvatarList;
