import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChildInTask, CourseContext, Task } from "../context/CourseContext";
import { AuthContext } from "../context/AuthContext";
import { InputField } from "../components/InputField";

interface TaskChildAvatarProps {
  child: ChildInTask;
  task: Task | null;
}

// Custom hook for managing tooltip visibility
const useTooltip = (isUpdating: boolean) => {
  const [hovered, setHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!hovered && !isUpdating) {
      timer = setTimeout(() => setShowTooltip(false), 200);
    } else {
      setShowTooltip(true);
    }

    return () => clearTimeout(timer);
  }, [hovered, isUpdating]);

  return { showTooltip, setHovered };
};

const TaskChildAvatar: React.FC<TaskChildAvatarProps> = ({ child, task }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const { showTooltip, setHovered } = useTooltip(isUpdating);
  const courseContext = useContext(CourseContext);
  const user = useContext(AuthContext); // Get user info from AuthContext

  if (!courseContext) {
    return (
      <div className="text-center text-red-500">
        Error: CourseContext is undefined. Please ensure the provider is wrapping this component.
      </div>
    );
  }

  const { handleUploadFile } = courseContext;

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmitFile = () => {
    if (task && file) {
      handleUploadFile(task.id, file ,child.id.toString() );
      setFile(null);
      setIsUpdating(false);
    }
  };

  const canUpdate =
    user?.user?.user_info.user_type === "student" && user.user.child?.id === child.id;

  return (
    <div
      className="relative flex flex-col items-center m-2"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <motion.div
        className="cursor-pointer"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <div
          className={`w-12 h-12 rounded-full flex items-center justify-center text-center m-auto ${
            child.solved && child.degree ? "bg-green-100 border-green-500" : "bg-red-100 border-red-500"
          } border-2 shadow-md`}
        >
          <div className="text-lg font-medium text-gray-600">
            {child.name.charAt(0).toUpperCase()}
          </div>
        </div>
        <div className="text-sm mt-1">{child.name}</div>
        <div className="text-xs mt-1">
          {child.solved ? "Solved" : "Unsolved"} ({child.degree} / 3)
        </div>
      </motion.div>

      <AnimatePresence>
        {showTooltip && (
          <motion.div
            className="absolute z-10 w-48 p-4 bg-white rounded-lg shadow-lg text-gray-800 top-16"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
          >
            <h4 className="font-semibold mb-2">{child.name}'s Details</h4>
            <p>
              <strong>Degree:</strong> {child.degree} / 3
            </p>
            {canUpdate && child.file_url && (
              <p className="mt-2">
                <strong>File:</strong>{" "}
                <a
                  href={child.file_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Preview File
                </a>
              </p>
            )}
            <p>
              <strong>Status:</strong> {child.solved ? "Solved" : "Unsolved"}
            </p>

            {canUpdate && (
              <>
                {!isUpdating && (
                  <button
                    onClick={() => setIsUpdating(true)}
                    className="mt-3 py-1 px-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-500 transition duration-200"
                  >
                    Upload File
                  </button>
                )}

                {isUpdating && (
                  <div className="mt-3">
                    <InputField
                      label="Upload File"
                      type="file"
                      name="fileUpload"
                      value=""
                      isEditing={true}
                      onChange={handleFileUpload}
                    />
                    <button
                      onClick={handleSubmitFile}
                      className="w-full mt-2 py-1 px-3 bg-green-500 text-white rounded-lg hover:bg-green-400 transition duration-200"
                    >
                      Submit File
                    </button>
                    <button
                      onClick={() => setIsUpdating(false)}
                      className="w-full mt-2 py-1 px-3 bg-red-500 text-white rounded-lg hover:bg-red-400 transition duration-200"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TaskChildAvatar;
