import React, { useState, useContext, useEffect } from "react";
import { CourseContext } from "../../context/CourseContext";
import { Task } from "../../context/CourseContext";
import TaskChildAvatar from "../../components/TasksChildrenAvatar";

interface TaskDetailProps {
  task: Task | null;
}

const TaskDetail: React.FC<TaskDetailProps> = ({ task }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedTask, setUpdatedTask] = useState<Task | null>(task);
  const [file, setFile] = useState<File | null>(null); // حالة للتعامل مع الملفات
  const [filePreview, setFilePreview] = useState<string | null>(null); // حالة لمعاينة الملف
  const courseContext = useContext(CourseContext);

  useEffect(() => {
    if (task) {
      // إنشاء نسخة من المهمة لتجنب التعديل المباشر
      const updatedTaskCopy = { ...task };
      if (updatedTaskCopy.name) {
        updatedTaskCopy.title = updatedTaskCopy.name; // تعيين العنوان من الاسم
      }
      setUpdatedTask(updatedTaskCopy); // تحديث الحالة
    }
  }, [task]);

  useEffect(() => {
    if (file) {
      // إنشاء معاينة للملف إذا كان صورة
      const previewUrl = URL.createObjectURL(file);
      setFilePreview(previewUrl);

      return () => URL.revokeObjectURL(previewUrl); // تنظيف رابط المعاينة عند إلغاء تحميل المكون
    }
  }, [file]);

  if (!courseContext) {
    return (
      <div className="text-center text-red-500">
        خطأ: سياق الدورة التدريبية غير معرّف. يرجى التأكد من تغليف هذا المكون بمزود السياق.
      </div>
    );
  }

  if (!task) {
    return (
      <div className="text-center text-red-500">لا توجد تفاصيل لهذه المهمة.</div>
    );
  }

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    if (updatedTask) {
      if (e.target.tagName === "SELECT") {
        setUpdatedTask({
          ...updatedTask,
          [name]: value === "active",
        });
      } else {
        setUpdatedTask({
          ...updatedTask,
          [name]: value,
        });
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const { handleUpdateTask } = courseContext;



  return (
    <div className="bg-gradient-to-r from-primary via-secondary to-secondary  p-8 rounded-2xl shadow-xl text-white transform transition duration-300 hover:scale-105 min-w-[320px] grid grid-cols-1 ">
      <h4 className="text-xl font-bold mb-6 max-w-[300px]">
        {isEditing ? "تعديل المهمة" : task.title || task.name}
      </h4>

      {/* صور الأطفال */}
      <div className="mb-6">
        <h5 className="text-xl font-semibold mb-4">الأطفال المكلفون:</h5>
        <div className="grid grid-cols-3 gap-4">
          {task.children && task.children.length > 0 ? (
            task.children.map((child) => (
              <TaskChildAvatar key={child.id} child={child} task={task} />
            ))
          ) : (
            <p className="text-gray-200">لا يوجد أطفال مكلفون بهذه المهمة.</p>
          )}
        </div>
      </div>

      <hr className="border-t-2 border-indigo-300 my-6" />

      {isEditing ? (
        <div className="space-y-6">
          <div>
            <label className="block font-medium text-gray-200 mb-2">العنوان</label>
            <input
              type="text"
              name="title"
              value={updatedTask?.title || task.name}
              onChange={handleInputChange}
              className="w-full p-3 rounded-lg bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition"
            />
          </div>
          <div>
            <label className="block font-medium text-gray-200 mb-2">الوصف</label>
            <textarea
              name="description"
              value={updatedTask?.description || ""}
              onChange={handleInputChange}
              className="w-full p-3 rounded-lg bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition"
              rows={4}
            />
          </div>
          <div>
            <label className="block font-medium text-gray-200 mb-2">الحالة</label>
            <select
              name="is_alive"
              value={updatedTask?.is_alive ? "active" : "inactive"}
              onChange={handleInputChange}
              className="w-full p-3 rounded-lg bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition"
            >
              <option value="active">أثناء المحاضرة</option>
              <option value="inactive">عمل منزلي</option>
            </select>
          </div>

          {/* تحميل ملف */}
          <div>
            <label className="block font-medium text-gray-200 mb-2">تحميل ملف</label>
            <input
              type="file"
              onChange={handleFileChange}
              className="w-full p-3 rounded-lg bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition"
            />
          </div>

          {/* معاينة الملف */}
          {filePreview && (
            <div className="mt-4">
              <h5 className="text-lg font-semibold text-gray-200">معاينة الملف:</h5>
              {file && file.type.startsWith("image/") ? (
                <img
                  src={filePreview}
                  alt="معاينة الملف"
                  className="max-w-full max-h-96"
                />
              ) : (
                <p className="text-gray-200">لا يمكن عرض معاينة لهذا النوع من الملفات.</p>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="space-y-4 text-lg">
          <p>
            <strong>الوصف:</strong> {task.description}
          </p>
          <p>
            <strong>طبيعة المهمة:</strong>{" "}
            <span className={task.is_alive ? "text-green-300" : "text-red-300"}>
              {task.is_alive ? "أثناء المحاضرة" : "عمل منزلي"}
            </span>
          </p>
          <p>
            <strong>وقت البداية:</strong>{" "}
            {new Date(task.start_time).toLocaleString("ar-EG")}
          </p>

          {/* عرض الملف */}
          {task.file && (
            <div className="mt-4">
              <h5 className="text-lg font-semibold text-gray-200">ملف المهمة:</h5>
              <a
                href={task.file}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-200 underline"
              >
                عرض ملف المهمة
              </a>
            </div>
          )}
        </div>
      )}

     
    </div>
  );
};

export default TaskDetail;
