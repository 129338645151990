import React from "react";

const UserActivities: React.FC = () => {
  return (
    <div className="min-h-screen bg-background text-primary p-8">
      <h1 className="text-3xl font-cairo font-bold">user Activities</h1>
      <p>Here you can manage your activities.</p>
    </div>
  );
};

export default UserActivities;
