import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { AuthContext } from './AuthContext';

export interface Essay {
  id: number;
  title: string;
  content: string;
  author: number;
  status: string;
  word_count: number;
  created_at: string;
  updated_at: string;
}

interface EssayContextType {
  essays: Essay[];
  fetchEssays: () => void;
  createEssay: (newEssay: Omit<Essay, 'id' | 'created_at' | 'updated_at'>) => void;
  updateEssay: (updatedEssay: Essay) => void;
  deleteEssay: (id: number) => void;
}

export const EssayContext = createContext<EssayContextType | null>(null);

const BASE_URL = 'https://codeoceantech.pythonanywhere.com/api/essays/';

const EssayProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [essays, setEssays] = useState<Essay[]>([]);
  const { user } = useContext(AuthContext) || { token: null }; // Get token from AuthContext

  // Function to get headers with authorization
  const getAuthHeaders = () => {
    return {
      'Content-Type': 'application/json',
      ...(user && { Authorization: `Bearer ${user?.token?.access}` }), // Include token in headers
    };
  };

  // Fetch Essays
  const fetchEssays = async () => {
    try {
      const response = await fetch(BASE_URL, {
        headers: getAuthHeaders(),
      });
      if (!response.ok) throw new Error('Failed to fetch essays');
      const data = await response.json();
      setEssays(data);
    } catch (error) {
      console.error('Error fetching essays:', error);
    }
  };

  // Create Essay
  const createEssay = async (newEssay: Omit<Essay, 'id' | 'created_at' | 'updated_at'>) => {
    try {
      const response = await fetch(BASE_URL, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(newEssay),
      });
      if (!response.ok) throw new Error('Failed to create essay');
      const data = await response.json();
      setEssays((prevEssays) => [...prevEssays, data]);
    } catch (error) {
      console.error('Error creating essay:', error);
    }
  };

  // Update Essay
  const updateEssay = async (updatedEssay: Essay) => {
    try {
      const response = await fetch(`${BASE_URL}${updatedEssay.id}/`, {
        method: 'PUT',
        headers: getAuthHeaders(),
        body: JSON.stringify(updatedEssay),
      });
      if (!response.ok) throw new Error('Failed to update essay');
      const data = await response.json();
      setEssays((prevEssays) =>
        prevEssays.map((essay) => (essay.id === data.id ? data : essay))
      );
    } catch (error) {
      console.error('Error updating essay:', error);
    }
  };

  // Delete Essay
  const deleteEssay = async (id: number) => {
    try {
      const response = await fetch(`${BASE_URL}${id}/`, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });
      if (!response.ok) throw new Error('Failed to delete essay');
      setEssays((prevEssays) => prevEssays.filter((essay) => essay.id !== id));
    } catch (error) {
      console.error('Error deleting essay:', error);
    }
  };

  // Fetch essays on mount
  useEffect(() => {
    if (user?.token?.access) {
      fetchEssays();
    }
  }, [user?.token?.access]);

  return (
    <EssayContext.Provider value={{ essays, fetchEssays, createEssay, updateEssay, deleteEssay }}>
      {children}
    </EssayContext.Provider>
  );
};

export { EssayProvider };
