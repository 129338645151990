import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

interface EssayFormProps {
  onSubmit: (formData: { title: string; content: string; status: string }) => void;
  initialData?: { title: string; content: string; status: string } | null;
}

const EssayForm: React.FC<EssayFormProps> = ({ onSubmit, initialData }) => {
  const [title, setTitle] = useState(initialData?.title || "");
  const [content, setContent] = useState(initialData?.content || "");
  const [status, setStatus] = useState(initialData?.status || "draft");

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.title);
      setContent(initialData.content);
      setStatus(initialData.status);
    }
  }, [initialData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({ title, content, status });
    resetForm();
  };

  const resetForm = () => {
    setTitle("");
    setContent("");
    setStatus("draft");
  };

  // Customize Quill toolbar options
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      ["link", "image"],
      [{ indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
    ],
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-gray-700">Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label className="block text-gray-700">Content</label>
        <ReactQuill
          value={content}
          onChange={setContent}
          className="w-full border rounded"
          theme="snow"
          modules={modules} // Pass the custom modules to Quill
        />
      </div>
      <div>
        <label className="block text-gray-700">Status</label>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-full p-2 border rounded"
          required
        >
          <option value="draft">Draft</option>
          <option value="submitted">Submitted</option>
          <option value="published">Published</option>
        </select>
      </div>
      <button
        type="submit"
        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
      >
        {initialData ? "Update Essay" : "Add Essay"}
      </button>
    </form>
  );
};

export default EssayForm;
