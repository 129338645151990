import { useNavigate } from "react-router-dom";

interface TokenErrorResponse {
  detail: string;
  code: string;
  messages: {
    token_class: string;
    token_type: string;
    message: string;
  }[];
}

const handleTokenError = () => {
  
    // Remove the token and user data from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    // Navigate to the login page

};

export default handleTokenError;
