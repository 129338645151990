import React from 'react';
import { Essay } from '../../context/EssayContext';

interface EssayListProps {
  essays: Essay[];
  onDelete: (id: number) => void;
  onEdit: (essay: Essay) => void;
}

const EssayList: React.FC<EssayListProps> = ({ essays, onDelete, onEdit }) => {
  return (
    <div>
      {essays.length === 0 ? (
        <p className="text-gray-500">No essays available.</p>
      ) : (
        <ul className="space-y-4">
          {essays.map((essay) => (
            <li
              key={essay.id}
              className="p-4 border rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow"
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-xl font-semibold text-gray-800">{essay.title}</h3>
                  <p className="text-gray-600 mt-2">
                    <div
                      className="essay-content-preview"
                      dangerouslySetInnerHTML={{ __html: essay.content }}
                    />
                  </p>
                </div>
                <div className="space-x-2">
                  <button
                    onClick={() => onEdit(essay)}
                    className="bg-blue-500 text-white py-1 px-3 rounded-md hover:bg-blue-600"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => onDelete(essay.id)}
                    className="bg-red-500 text-white py-1 px-3 rounded-md hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default EssayList;
