import { Session } from "../../context/CourseContext";

export  const getFormattedStartTime = (session: Session) => {
    const exactStartDate = new Date(session.exact_start_date);
    const scheduledStartDate = new Date(session.start_time);
  
    // Check if both dates are on the same day
    const isSameDay =
      exactStartDate.getFullYear() === scheduledStartDate.getFullYear() &&
      exactStartDate.getMonth() === scheduledStartDate.getMonth() &&
      exactStartDate.getDate() === scheduledStartDate.getDate();
  
    // Calculate the difference in days
    const dayDifference = Math.abs(
      (exactStartDate.getTime() - scheduledStartDate.getTime()) / (1000 * 60 * 60 * 24)
    );
  
    if (!isSameDay && dayDifference > 10) {
      // If dates are not the same and differ by more than 10 days
      return `لم تبدأ بعد، الوقت المحدد: ${scheduledStartDate.toLocaleString("ar-EG", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })}`;
    } else if (isSameDay) {
      // If dates are on the same day
      return `التوقيت الفعلي: ${exactStartDate.toLocaleString("ar-EG", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })}، الوقت المحدد: ${scheduledStartDate.toLocaleString("ar-EG", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })}`;
    } else {
      // If dates differ but within 10 days
      return `لم تبدأ بعد، الوقت المحدد: ${scheduledStartDate.toLocaleString("ar-EG", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })}`;
    }
  };
  
  // استخدام الدالة

  