import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext"; // Import your AuthContext
import useNotification from "./useNotification"; // Import the notification hook

// Interfaces
export interface Activity {
  id: number;
  user: number;
  title: string;
  description: string;
  activity_type: string;
  child_id: number;
  child_name: string;
  task_id: number;
  is_seen: boolean;
  is_loading?: boolean; // Optional loading state for activity
  link?: string; // Optional: Include file link for activities with uploads
}

interface ActivitiesContextProps {
  activities: Activity[];
  fetchActivities: () => Promise<void>;
  markAsSeen: (activity: Activity) => Promise<void>;
}

// Context Initialization
const ActivitiesContext = createContext<ActivitiesContextProps | null>(null);

// Provider Component
export const ActivitiesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const auth = useContext(AuthContext); // Get the auth token from AuthContext
  const triggerToast = useNotification(); // Notification hook

  // Fetch activities
  const fetchActivities = async () => {
    if (!auth?.user?.token?.access) {
      console.error("Access token is missing");
      return;
    }

    try {
      const response = await axios.get<Activity[]>(
        "https://codeoceantech.pythonanywhere.com/api/activities/",
        {
          headers: {
            Authorization: `Bearer ${auth?.user?.token?.access}`,
          },
        }
      );

      const newActivities = response.data.filter((activity) => !activity.is_seen);
      if (newActivities.length > 0) {
        handleNewActivities(newActivities);
      }

      setActivities(response.data); // Update activities state
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  // Notify for unseen activities one by one
  const handleNewActivities = async (newActivities: Activity[]) => {
    for (const activity of newActivities) {
      // Trigger notification based on activity type
      if (activity.activity_type === "file_upload") {
        triggerToast(
          `You have a new file uploaded by ${activity.child_name}: ${activity.title}`,
          "warning"
        );
      } else if (activity.activity_type === "message") {
        triggerToast(
          `New message from ${activity.child_name}: ${activity.description}`,
          "info"
        );
      }

      // Mark the activity as seen after previewing
      await markAsSeen(activity);

      // Wait for 3 seconds before processing the next activity
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  };

  // Mark activity as seen
  const markAsSeen = async (activity: Activity) => {
    if (!auth?.user?.token?.access) {
      console.error("Access token is missing");
      return;
    }

    try {
      await axios.put(
        `https://codeoceantech.pythonanywhere.com/api/activities/${activity.id}/`,
        {title: activity.title,
        description: activity.description,
        is_seen: true},
        {
          headers: {
            Authorization: `Bearer ${auth?.user?.token?.access}`,
          },
        }
      );

      // Update activities state locally
      setActivities((prevActivities) =>
        prevActivities.map((a) =>
          a.id === activity.id ? { ...a, is_seen: true } : a
        )
      );
    } catch (error) {
      console.error("Error marking activity as seen:", error);
    }
  };

  // Periodic fetching of activities every minute
  useEffect(() => {
    fetchActivities()
  }, []);
  useEffect(() => {
    const interval = setInterval(fetchActivities, 60000); // Fetch every 1 minute
    return () => clearInterval(interval); // Cleanup on unmount
  }, [auth?.user?.token?.access]);

  return (
    <ActivitiesContext.Provider
      value={{ activities, fetchActivities, markAsSeen }}
    >
      {children}
    </ActivitiesContext.Provider>
  );
};

// Custom Hook
export const useActivities = () => {
  const context = useContext(ActivitiesContext);
  if (!context) {
    throw new Error("useActivities must be used within an ActivitiesProvider");
  }
  return context;
};
