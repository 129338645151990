import React, { useState, useEffect, useContext } from "react";
import { CourseContext, Task } from "../../context/CourseContext";
import TaskDetail from "./TaskDetail";

interface TaskListProps {
  tasks?: Task[];
}

const TaskList: React.FC<TaskListProps> = ({ tasks }) => {
  const [taskList, setTaskList] = useState<Task[] | undefined>(tasks);
  const courseContext = useContext(CourseContext);

  useEffect(() => {
    if (courseContext) {
      const { activeSessionChildrenAbsent, activeSessionChildrenAttendance, activeSessionChildrenTasks } = courseContext;
    }
  }, [courseContext]);

  useEffect(() => {
    setTaskList(tasks);
  }, [tasks]);

  if (!courseContext) {
    return (
      <div>
        Error: CourseContext is undefined. Please ensure the provider is wrapping this component.
      </div>
    );
  }

  const { activeSessionChildrenTasks } = courseContext;

  return (
    <div className="task-list flex flex-wrap gap-6">
      {!taskList || taskList.length === 0 ? (
        <p>No tasks available.</p>
      ) : (
        taskList.map((task) => <TaskDetail key={task.id} task={task} />)
      )}
    </div>
  );
};

export default TaskList;
