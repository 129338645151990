import React, { useContext, useEffect, useState } from "react";
import { CourseContext, Problem, Course, Session } from "../../context/CourseContext";
import { AuthContext, User } from "../../context/AuthContext";

const UserProblems: React.FC = () => {
  const courseContext = useContext(CourseContext);
  const authContext = useContext(AuthContext);
  const [userData, setuserData] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);

  // Fetch data on mount and when refreshing
  const fetchData = async () => {
    if (courseContext) {
      setLoading(true);
      try {
        const { courses, activeCourse, handleSelectCourse, fetchProblems } = courseContext;
        if (courses.length > 0 && !activeCourse) {
          handleSelectCourse(courses[0]);
        }
        await fetchProblems();
      } catch (error) {
        console.error("Error fetching problems:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    if (authContext && authContext.user) {
      setuserData(authContext.user);
    }
  }, [authContext]);

  if (!courseContext) {
    return (
      <div className="text-center mt-10 text-error">
        Error: CourseContext is undefined. Please ensure the provider is wrapping this component.
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="text-center mt-10">
        <h2 className="text-2xl font-semibold text-error">user not found.</h2>
        <p>Please make sure the link is correct or try again later.</p>
      </div>
    );
  }

  const { problems, courses } = courseContext;

  // Helper function to find the session related to the problem
  const findSessionForProblem = (problem: Problem): { session?: Session; course?: Course } => {
    for (const course of courses) {
      const session = course.sessions.find((session) => session.id === problem.session);
      if (session) {
        return { session, course };
      }
    }
    return {};
  };

  return (
    <div className="min-h-screen bg-background text-primary p-8">
      <h1 className="text-4xl md:text-5xl font-cairo font-bold mb-6 animate-fadeIn">
        user Problems
      </h1>
      <p className="mb-6 text-lg animate-fadeIn text-secondary">
        Here you can manage your problems efficiently and effortlessly.
      </p>

      {/* Refresh Button */}
      <button
        onClick={fetchData}
        className={`mb-6 px-6 py-3 bg-primary text-white font-semibold rounded shadow-md hover:bg-accent transition-transform transform ${loading ? "scale-95" : "hover:scale-105"} duration-300 ease-in-out`}
        disabled={loading}
      >
        {loading ? "Refreshing..." : "Refresh Problems"}
      </button>

      {/* Problems List */}
      {loading ? (
        <p className="text-center text-lg font-semibold animate-pulse text-secondary">
          Loading problems...
        </p>
      ) : problems && problems.length > 0 ? (
        <div className="flex flex-wrap gap-8 justify-center">
          {problems.map((problem: Problem, index) => {
            const { session, course } = findSessionForProblem(problem);

            return (
              <div
                key={problem.id}
                className={`w-full md:w-[360px] h-[320px] bg-white rounded-2xl flex flex-col justify-between shadow-lg transform hover:-translate-y-3 hover:shadow-2xl transition-all duration-500 ease-out animate-fadeInUp`}
                style={{ animationDelay: `${index * 100}ms` }}
              >
                {/* Header Section */}
                <div className="p-5 flex items-center gap-4">
                  <img
                    className="w-16 h-16 rounded-full border-2 border-primary object-cover"
                    src={authContext?.user?.user_info.profile_image || "https://via.placeholder.com/60x60"}
                    alt="Reporter"
                  />
                  <div>
                    <div className="text-primary text-lg font-semibold">{problem.reported_by}</div>
                    <div className="text-secondary text-sm">{course?.title || "Unknown Course"}</div>
                  </div>
                </div>

                {/* Problem Description Section */}
                <div className="p-5 flex-1 overflow-hidden">
                  <p className="text-accent text-base font-medium line-clamp-3 leading-snug">
                    {problem.description}
                  </p>
                </div>

                {/* Footer Section */}
                <div className="p-5 text-secondary text-sm flex justify-between items-center">
                  <span>{new Date(problem.created_at).toLocaleDateString()}</span>
                  <span className="text-success font-semibold">New</span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-center text-lg font-semibold text-accent animate-fadeIn">
          No problems found.
        </p>
      )}
    </div>
  );
};

export default UserProblems;
