import React, { createContext, useState, useEffect, ReactNode, useCallback } from "react";
import axios from "axios";

// Interfaces for Child and Parent
export interface Child {
  id: number;
  name: string;
  age: number;
  experience: string;
  clear_password: string;
  clear_username: string;
  is_active: boolean;
}

export interface Parent {
  user: number;
  children: Child[];
}

// User Info Interface
export interface UserInfo {
  user_id: number;
  username: string;
  phone_number: string;
  profile_image: string | null;
  email: string | null;
  user_type: "student" | "parent"; // Could be either student or parent
}

// Token Interface
export interface Token {
  access: string;
  refresh: string;
}

// Full User Data Interface (Including Token and Role Data)
export interface User {
  user_info: UserInfo;
  token: Token;
  child: Child | null;
  parent: Parent | null;
}

// Auth Context Interface
interface AuthContextProps {
  user: User | null;
  loading: boolean;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
  refreshToken: () => Promise<void>;
  updateUser: (id: number, formData: FormData, onProgress: (progress: number) => void) => Promise<User>;

}

// Create Context
export const AuthContext = createContext<AuthContextProps | null>(null);

// AuthProvider Component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Load stored data on component mount
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");

    if (storedToken && storedUser) {
      setUser({
        token: JSON.parse(storedToken),
        ...JSON.parse(storedUser),
      });
    }

    setLoading(false);
  }, []);

  // Login Function
  const login = async (username: string, password: string): Promise<boolean> => {
    try {
      const response = await axios.post("https://codeoceantech.pythonanywhere.com/api/accounts/login/", {
        username,
        password,
      });

      const { access, refresh } = response.data.token;
      const user_info: UserInfo = response.data.user_info;
      const token: Token = { access, refresh };

      // Role-specific data (either child or parent)
      const childData: Child = response.data.child || null;
      const parentData: Parent = response.data.parent || null;

      const userData: User = {
        user_info,
        token,
        child: childData,
        parent: parentData,
      };

      // Store data in localStorage
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("user", JSON.stringify(userData));

      setUser(userData);

      return true; // Login successful
    } catch (error) {
      console.error("Login failed:", error);
      return false; // Login failed
    }
  };

  // Logout Function
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
  };

  // Refresh Token Function
  const refreshToken = useCallback(async () => {
    if (!user?.token.refresh) return;

    try {
      const response = await axios.post("https://codeoceantech.pythonanywhere.com/api/token/refresh/", {
        refresh: user.token.refresh,
      });

      const updatedToken: Token = {
        access: response.data.access,
        refresh: user.token.refresh,
      };

      // Store updated token in localStorage
      localStorage.setItem("token", JSON.stringify(updatedToken));
      setUser((prevState) => (prevState ? { ...prevState, token: updatedToken } : prevState));
    } catch (error) {
      console.error("Token refresh failed:", error);
      logout(); // Logout on token refresh failure
    }
  }, [user]);
  const updateUser = async (id: number, formData: FormData, onProgress: (progress: number) => void): Promise<User> => {
    try {
      const response = await axios.put(
        `https://codeoceantech.pythonanywhere.com/api/accounts/users/${id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              onProgress(progress);
              
            }
          },
        }
      );
      
      return response.data; // Return updated user data
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  };
  return (
    <AuthContext.Provider value={{ user, loading, login, logout, refreshToken,updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};
