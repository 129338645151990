// src/components/ProtectedRoute.tsx
import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const ProtectedRoute: React.FC = () => {
  const { user, loading } = useContext(AuthContext)!;

  // If the user is still loading or not authenticated, redirect to login page
  if (loading) {
    return <div>Loading...</div>; // Optional loading state
  }

  if (!user) {
    return <Navigate to="/login" replace />; // Redirect to login if not authenticated
  }

  return <Outlet />; // Render child routes if authenticated
};

export default ProtectedRoute;
