import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { Session, Task } from "../../context/CourseContext";

export interface ChatMessage {
  child_name: string;
  id: number | null;
  sender: string;
  message: string;
  timestamp: string;
  created_by_child?: number | null | string;
  is_seen: boolean;
  is_child_seen?: boolean;
  task: number | Task | undefined;
}

interface AskuserModalProps {
  showModal: boolean;
  activeSession: Session;
  handleModalClose: () => void;
  taskToAsk?: number | Task | undefined;
  child_id?: number;
  child: string;
  userRole: "student" | "user";
}

const AskuserModal: React.FC<AskuserModalProps> = ({
  showModal,
  handleModalClose,
  taskToAsk,
  activeSession,
  child_id,
  userRole,
  child,
}) => {
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [userInput, setUserInput] = useState<string>("");
  const [selectedTask, setSelectedTask] = useState<Task | number | undefined>(taskToAsk);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const {  user } = useContext(AuthContext) || {};

  useEffect(() => {
    if (showModal) fetchMessages();
    console.log(userRole);
    const intervalId = setInterval(() => {
      if (showModal) fetchMessages();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [showModal, selectedTask]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `https://codeoceantech.pythonanywhere.com/api/chat-messages/?session_id=${activeSession.id}`
      );
      setChatMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setUserInput(value);

    const lastWord = value.trim().split(/\s+/).pop() || "";

    if (lastWord.startsWith("@") || lastWord.startsWith("#")) {
      setShowSuggestions(true);
      setFilteredTasks(getFilteredTasks(lastWord));
    } else {
      setShowSuggestions(false);
      setFilteredTasks([]);
    }
  };

  const getFilteredTasks = (input: string): Task[] => {
    const inputWithoutSymbol = input.replace(/^[@#]/, "").toLowerCase();
    return (
      activeSession.get_tasks?.filter((task) =>
        typeof task !== "number" && task.title?.toLowerCase().includes(inputWithoutSymbol)
      ) || []
    );
  };

  const handleTaskSelect = (task: Task) => {
    setSelectedTask(task);
    setUserInput(userInput.replace(/[@#]\S*$/, "").trim()); // Remove mention from input
    setShowSuggestions(false);
  };

  const handleRemoveSelectedTask = () => {
    setSelectedTask(undefined);
  };

  const handleSendMessage = async () => {
    if (userInput.trim()) {
      if (user?.child?.name){

        const newMessage: ChatMessage = {
          child_name : user?.child?.name ,
          id: null,
          sender: 'student',
          message: userInput,
          timestamp: new Date().toISOString(),
          created_by_child: user?.child?.id,
          is_seen: false,
          task: typeof selectedTask !== "number" ? selectedTask?.id : selectedTask,
        };
  
        setChatMessages((prevMessages) => [...prevMessages, newMessage]);
        setUserInput("");
  
        try {
          await axios.post(`https://codeoceantech.pythonanywhere.com/api/chat-messages/`, {
            ...newMessage,
            session_id: activeSession.id,
          });
        } catch (error: any) {
          console.error("Error sending message:", error.response?.data || error.message);
        }
      }
    }
  };
  const formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("ar-EG", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const formatTime = (timestamp: string): string => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString("ar-EG", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  return (
    <div className={`absolute top-0 inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${showModal ? "opacity-100 visible" : "opacity-0 invisible"}`}>
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-lg">
        <button className="text-black text-xl font-bold" onClick={handleModalClose}>
          &times;
        </button>

        <h2 className="text-green-600 text-xl font-semibold mb-4 text-center">
          {userRole === "student" ? "اسأل المدرس" : "Elmohandes Conversations"}
        </h2>

        <div className="border-t border-b border-gray-300 rounded-lg p-4 mb-4 h-64 overflow-y-auto flex flex-col space-y-2" dir="rtl">
        {chatMessages.map((chat) => (
            <div
              key={chat.id}
              className={`mb-2 p-3 rounded-lg max-w-xs ${
                chat.sender === "student" ? "bg-blue-100 self-end" : "bg-green-100 self-start"
              }`}
            >
              <p className="font-bold text-sm">
                {chat.sender === "student" ? "الطالب" : "المدرس"}
              </p>
              <p className="text-sm">
              {chat.task && typeof chat.task !== "number" ? chat.task?.title : activeSession.get_tasks?.find((task) => task.id === chat.task)?.title}
              </p>
              <p className="text-sm">{chat.message}</p>
              <p className="text-xs text-gray-600 mt-1">
                📅 {formatDate(chat.timestamp)} | ⏰ {formatTime(chat.timestamp)}
              </p>
              {typeof chat.task !== "number" && chat.task?.title && (
                <p className="text-xs text-gray-700 mt-1">
                  <span className="font-bold">المهمة:</span> {chat.task.title}
                </p>
              )}
            </div>
          ))}
        </div>

        <div className="mb-2">
          {typeof selectedTask !== "number" && selectedTask?.title && (
            <div className="bg-gray-200 p-2 rounded-lg flex items-center justify-between">
              <span className="text-sm">{selectedTask.title}</span>
              <button className="text-red-500" onClick={handleRemoveSelectedTask}>
                &times;
              </button>
            </div>
          )}
        </div>

        <textarea
          className="w-full p-3 rounded-lg border border-gray-300 focus:ring focus:ring-green-500 mb-2 resize-none"
          dir="rtl"
          placeholder="اكتب رسالتك هنا..."
          value={userInput}
          onChange={handleInputChange}
          rows={3}
        />

        {showSuggestions && (
          <ul className="bg-white border rounded-lg p-2 mt-2 max-h-40 overflow-y-auto shadow-md">
            {filteredTasks.map((task) => (
              <li
                key={task.id}
                className="p-2 cursor-pointer hover:bg-gray-200 rounded-lg"
                onClick={() => handleTaskSelect(task)}
              >
                {task.title}
              </li>
            ))}
          </ul>
        )}

        <div className="mt-4 flex justify-end">
          <button className="bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600" onClick={handleSendMessage}>
            إرسال
          </button>
        </div>
      </div>
    </div>
  );
};

export default AskuserModal;
