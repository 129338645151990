import { Session } from "../../context/CourseContext";

export const getSessionStatusDetails = (session: Session) => {
    const now = new Date(); // الوقت الحالي
    const exactStartDate = new Date(session.exact_start_date);
    const exactEndDate = new Date(session.exact_end_date);
  
    if (!session.is_Started) {
      return "الجلسة لم تبدأ بعد.";
    }
  
    if (session.is_Started && !session.is_ended) {
      // الجلسة بدأت ولم تنتهِ بعد
      const durationInHours = ((now.getTime() - exactStartDate.getTime()) / (1000 * 60 * 60)).toFixed(2);
      return `الجلسة بدأت يوم ${exactStartDate.toLocaleString("ar-EG", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })}، ومرت ${durationInHours} ساعة منذ بدايتها.`;
    }
  
    if (session.is_Started && session.is_ended) {
      // الجلسة بدأت وانتهت
      const durationInHours = ((exactEndDate.getTime() - exactStartDate.getTime()) / (1000 * 60 * 60)).toFixed(2);
      return `الجلسة بدأت يوم ${exactStartDate.toLocaleString("ar-EG", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })} وانتهت يوم ${exactEndDate.toLocaleString("ar-EG", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })} واستمرت لمدة ${durationInHours} ساعة.`;
    }
  
    return "تعذر تحديد حالة الجلسة.";
  };
  
  