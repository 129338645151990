// components/TabNavigation.tsx
import React from 'react';

interface TabNavigationProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const TabNavigation: React.FC<TabNavigationProps> = ({ activeTab, onTabChange }) => (
  <div className="w-[217px] h-[461px] relative col-span-2">
    <div className="h-[251.27px] top-[37.49px] absolute flex-col gap-6">
      {['personal', 'contact', 'editAll'].map((tab) => (
        <div
          key={tab}
          className={`pl-3 pr-[90px] py-3 rounded-lg cursor-pointer ${
            activeTab === tab ? 'bg-[#eaf8f5]' : ''
          }`}
          onClick={() => onTabChange(tab)}
        >
          <div className="text-[#083a50] text-base font-bold">{tab}</div>
        </div>
      ))}
    </div>
  </div>
);

export default TabNavigation;
