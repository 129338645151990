import { useToast } from "./ToastContext";
import { v4 as uuidv4 } from "uuid";

const useNotification = () => {
  const { addToast } = useToast();

  const triggerToast = (message: string, type: "success" | "error" | "info" | "warning") => {
    const id = uuidv4();
    addToast({ id, message, type });
  };

  return triggerToast;
};

export default useNotification;
