// components/ProfileImageUpload.tsx
import React from 'react';
import ImageUpload from '../ImageUpload';

interface ProfileImageUploadProps {
  image: File | string | null;
  handleImageChange: (file: File | string | null) => void;
}

const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({ image, handleImageChange }) => (
  <div>
    <ImageUpload initialImage={image} onImageChange={handleImageChange} />
  </div>
);

export default ProfileImageUpload;
