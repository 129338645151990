import { useState } from "react";
import { useActivities } from "../../context/ActivitiesContext";

export interface Activity {
  id: number;
  user: number;
  title: string;
  description: string;
  activity_type: string;
  child_id: number;
  child_name: string;
  task_id: number;
  is_seen: boolean;
  is_loading?: boolean;
  link?: string;
}

interface ActivitiesNavbarProps {
  isOpen: boolean;
  toggleActivities: () => void;
}

const ActivitiesNavbar: React.FC<ActivitiesNavbarProps> = ({ isOpen, toggleActivities }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [isNotiOpen, setIsNotiOpen] = useState(false);

  // Destructure the activities array from the context
  const { activities } = useActivities(); // Assuming useActivities returns { activities: Activity[] }

  // Filter activities based on search and filter criteria
  const filteredActivities = activities.filter((activity: Activity) => {
    const matchesSearchQuery =
      activity.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      activity.activity_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      activity.child_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      activity.description.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesFilterType = filterType === "" || activity.activity_type === filterType;

    const matchesFilterStatus =
      filterStatus === "all" || (filterStatus === "seen" && activity.is_seen) || (filterStatus === "unseen" && !activity.is_seen);

    return matchesSearchQuery && matchesFilterType && matchesFilterStatus;
  });

  return (
    <nav className="bg-secondary p-4 rounded-lg shadow-md w-full md:w-[70vw]">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <label className="mr-2 text-textBackground font-semibold">Toggle Activities</label>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isOpen}
              onChange={toggleActivities}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary rounded-full peer dark:bg-gray-700 peer-checked:bg-primary transition-all"></div>
          </label>
        </div>

        <div className="relative">
          <button
            type="button"
            className="relative rounded-full bg-accent p-2 text-textBackground hover:text-white focus:outline-none focus:ring-2 focus:ring-primary"
            onClick={() => setIsNotiOpen(!isNotiOpen)}
          >
            <span className="sr-only">View notifications</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
              />
            </svg>
          </button>
        </div>
      </div>

      {isNotiOpen && (
        <div className="absolute right-10 mt-2 w-80 bg-white rounded-lg shadow-xl ring-1 ring-black/10 p-4 z-10">
          <div className="mb-3">
            <input
              type="text"
              placeholder="Search activities..."
              className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-primary focus:ring focus:ring-primary-200"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <select
              className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-primary focus:ring focus:ring-primary-200"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="">All Categories</option>
              <option value="file_upload">File Upload</option>
              <option value="text">Message</option>
            </select>
          </div>

          <div className="mb-3">
            <select
              className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-primary focus:ring focus:ring-primary-200"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="all">All Statuses</option>
              <option value="seen">Seen</option>
              <option value="unseen">Unseen</option>
            </select>
          </div>

          <ul className="max-h-60 overflow-y-auto divide-y divide-gray-200">
            {filteredActivities.length > 0 ? (
              filteredActivities.map((activity: Activity) => (
                <li key={activity.id} className="py-3">
                  <div className="font-semibold text-textPrimary">{activity.title}</div>
                  <div className="font-semibold text-textSecondary bg-error px-2 py-1 rounded w-fit">({activity.child_name})</div>
                  <div className="text-sm text-textMuted">{activity.activity_type}</div>
                  <div className={`text-sm text-textMuted border-2 ${activity.is_seen ? "border-success" : "border-error"} rounded p-1`}>{activity.description}</div>
                </li>
              ))
            ) : (
              <li className="py-3 text-center text-textMuted">No activities found</li>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default ActivitiesNavbar;
