// components/UploadProgressBar.tsx
import React from 'react';

interface UploadProgressBarProps {
  progress: number;
}

const UploadProgressBar: React.FC<UploadProgressBarProps> = ({ progress }) => (
  <div className="w-full bg-gray-200 h-2 rounded-full">
    <div className="bg-blue-500 h-2 rounded-full" style={{ width: `${progress}%` }}></div>
  </div>
);

export default UploadProgressBar;
