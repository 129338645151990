import React, { createContext, useState, useEffect, ReactNode, useContext } from "react";
import axios from "axios";
import { AuthContext, Token, User } from "./AuthContext";
import  useNotification from "./useNotification"; // Import the notification hook
import handleTokenError from "../components/TokenErrorResponse";

// Interfaces for data structures
export interface ChildInTask {
  id: number;
  name: string;
  solved: boolean;
  degree?: number;
  file_url?: string;
  task_completions?: TaskCompletion[];  // Add task completions to track child completions

}
export interface TaskCompletion {
  child_id: number;  // Child identifier
  degree: number;  // Degree/score for the task
  file_url?: string;  // URL of the uploaded file
  completed_at: string;  // Date and time when the task was completed
  task:number
}

export interface Task {
  description: string;
  file?: string;
  duration_days: number;
  duration_minutes: number;
  id: number;
  is_alive: boolean;
  session_id: number;
  start_time: string;
  title?: string;
  name?: string;
  children?: ChildInTask[]

}

export interface Session {
  id: number;
  title: string;
  description: string;
  meeting_link: string;
  start_time: string;
  exact_start_date: string; // keep in mind that it is date time for starting session from user
  end_time: string;
  exact_end_date: string; //keep in mind that it is date time for ending session from user
  is_Started: boolean; // while user starting it changing  exact_start_date 
  is_ended: boolean; // while user ending it changing  exact_end_date 
  user_attended_name?: string;
  get_tasks?: Task[];
  attendance?: number[];
  absent?: number[];
  get_attendance_usernames?: string[];
}
export interface Problem {
  id: number;
  session: number;
  description: string;
  reported_by: string; // Assuming reported_by is the username
  created_at: string;
  owner: string;
}
export interface Course {
  id: number;
  title: string;
  description: string;
  start_time: string;
  end_time: string;
  sessions: Session[];

}

export interface Child {
  id: number;
  username: string;
  name: string;
  course: string;
  isLoading: boolean;
  avatar_url?: string;

}
export interface ChildInCourse {
  id: number;
  name: string;
  avatar_url?: string;
  solved: boolean;
}


// Context Props Interface
interface CourseContextProps {
  courses: Course[];
  problems: Problem[];
  taskDegrees: TaskCompletion[] ;
  activeSessionChildren: any[];
  activeSessionChildrenAbsent: ChildInCourse[];
  activeSessionChildrenAttendance: ChildInCourse[];
  activeSessionChildrenTasks: Task[] | undefined;
  activeSession: Session | null;
  activeCourse: Course | null;
  fetchCourses: () => void;
  fetchProblems: () => Promise<void>;
  fetchTaskDegrees: (sessionId: number) => void;  // Add this line
  postProblem: (sessionId: number, problemDescription: string,) => Promise<void>;
  handleToggleSession: (session: Session, course: number, is_ended?: boolean) => void;
  handleCreateTask: (newTask: { name: string; is_alive: boolean; description: string; sessionId: number }) => void;
  handleUpdateTaskDegree: (taskId: number, childId: number, degree: number) => void;
  handleAddChildAction: (child: number, session_id: number) => void;
  handleAddChildActionTask: (child: ChildInTask, task_id: number, is_solved:boolean) => void;
  handleSelectCourse: (course: Course | null) => void;
  handleSelectSession: (session: Session | null) => void;
  handleUpdateTask: (updatedTask: Task, file: File | null) => void;
  handleUploadFile: (taskId: number, file: File ,child_id:string)  => void;
}

// Context Creation
const CourseContext = createContext<CourseContextProps | undefined>(undefined);

const CourseProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [problems, setProblems] = useState<Problem[]>([]);
  const [activeSession, setActiveSession] = useState<Session | null>(null);
  const [activeCourse, setActiveCourse] = useState<Course | null>(null);
  const [activeSessionChildren, setActiveSessionChildren] = useState<any[]>([]);
  const [activeSessionChildrenAbsent, setActiveSessionChildrenAbsent] = useState<any[]>([]);
  const [activeSessionChildrenAttendance, setActiveSessionChildrenAttendance] = useState<any[]>([]);
  const [activeSessionChildrenTasks, setActiveSessionChildrenTasks] = useState<Task[] | undefined>([]);
  const auth = useContext(AuthContext); // Get token from AuthContext
  const [token, setToken] = useState<Token>();
  const [user_id, setUser_id] = useState<number>();
  const triggerToast = useNotification();
  const [username, setUsername] = useState('')
  const [taskDegrees, setTaskDegrees] = useState<TaskCompletion[]>([]);  // Add state to store the task degrees

  const loadLocalStorageData = () => {
    const storedTokenString = localStorage.getItem("token");
    if (storedTokenString) {
      const storedToken: Token = JSON.parse(storedTokenString);
      
      if (storedToken && storedToken.access) {
        setToken(storedToken);
      } else {
        console.warn("Invalid token format in localStorage");
      }
    }

    const storedUserString = localStorage.getItem("user");
    if (storedUserString) {
      const storedUser: User = JSON.parse(storedUserString);
      setUsername(storedUser.user_info.username);
      setUser_id(storedUser.user_info.user_id);
    }
  };

  // Load data on component mount and listen for localStorage changes
  useEffect(() => {
    loadLocalStorageData();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "token" || event.key === "user") {
        loadLocalStorageData();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    loadLocalStorageData();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "token" || event.key === "user") {
        loadLocalStorageData();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [localStorage.getItem("token")]);

  

  const [isUpdated, setIsUpdated] = useState(false); // Track updates
  // Fetch problems for a specific session


  const fetchProblems = async () => {

    try {
      const response = await fetch(
        `https://codeoceantech.pythonanywhere.com/api/problems/?reported_by=${user_id}`,
        {        headers: { Authorization: `Token ${token?.access}` },
      }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch problems");
      }
      const data = await response.json();
      setProblems(data);
    } catch (err: any) {
      // triggerToast(`Error creating ${err}`, "error"); // Trigger error toast
    }
  };
  const fetchTaskDegrees = async (sessionId: number) => {
    if (!username) {
      // alert("فشل إرسال الطلب: اسم المستخدم غير متاح.");
      return;
    }
  
    try {
      console.log("Starting to fetch task degrees for session:", sessionId);
  
      // Send GET request with session_id as a query parameter
      const response = await axios.get(
        `https://codeoceantech.pythonanywhere.com/api/sessions/${sessionId}/tasks/`,
        { params: { session_id: sessionId } }
      );
      // console.log("Received response:", response.data);
  
      // Check if the response data is an array
      if (!Array.isArray(response.data)) {
        console.error("Unexpected response format:", response.data);
        // alert("فشل إرسال الطلب: تنسيق الاستجابة غير متوقع.");
        return;
      }
  
      // Update the task degrees state
      // console.log("Updating task degrees state with the response data.");
      setTaskDegrees(response.data);
      
      // Ensure `activeSession` is defined and create a new active session copy
      if (!activeSession) {
        // console.log("No active session found, aborting session update.");
        return;
      }
  
      // console.log("Active session found, proceeding to update session.");
  
  
      // Set the updated session back to active session
      // setActiveSession(newActiveSession);
    } catch (error) {
      // console.error("Error occurred while fetching task degrees:", error);
      // triggerToast(`Error: ${error}   555`, "error"); // Trigger error toast
      // alert("فشل إرسال الطلب.");
    }
  };
  
  
  
  
  
  const fetchProblemsFor_session = async (sessionId: number) => {
    
    try {
      const response = await fetch(
        `https://codeoceantech.pythonanywhere.com/api/problems/?session=${sessionId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch problems");
      }
      const data = await response.json();
      setProblems(data);
    } catch (err: any) {
      triggerToast(`Error creating ${err}`, "error"); // Trigger error toast
    }
  };
    // Post a new problem for a specific session
    const postProblem = async (sessionId: number, problemDescription: string,) => {
      triggerToast(`trying tp send problem`, "warning"); // Trigger error toast
      const reported_by = username
      // console.log(reported_by);
      try {
        const response = await fetch(`https://codeoceantech.pythonanywhere.com/api/problems/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            session: sessionId,
            description: problemDescription,
            reported_by: username,
          }),
        });
  
        if (!response.ok) {
          throw new Error("Failed to post problem");
        }
  
        triggerToast(` problem sended successfully`, "success"); // Trigger error toast
        fetchProblems(); // Re-fetch problems to update the list
      } catch (err: any) {
        triggerToast(` problem  not sended  try again later ${err}`, "error"); // Trigger error toast

      } 
    };
  // Fetch courses
  const fetchCourses = async () => {
    try {
      const response = await axios.get<Course[]>(`https://codeoceantech.pythonanywhere.com/api/courses2/`, {
        headers: { Authorization: `Bearer ${token?.access}` },
        

      });
      setCourses((prevCourses) => {
        const updatedCourses = response.data;
        return updatedCourses.length > 0 ? updatedCourses : prevCourses; // Keep current data if no updates.
      });
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  

  useEffect(() => {
    triggerToast("DATA UPDATED ", "success");
    if (activeSession) {
      fetchTaskDegrees(activeSession.id);
      fetchTaskDegrees(activeSession.id)
    }
    // console.log(activeSessionChildrenTasks);
    const updatedChildrenTasks = activeSessionChildrenTasks?.map((task) => {
      // console.log("Processing task:", task.id);
    
      return {
        ...task,
        children: task.children?.map((childTask) => {
          // console.log("Processing childTask:", childTask.id);
          // console.log(childTask);
          // Find the matching degree for this childTask
          const matchingDegree = taskDegrees.find(
            (degree) => degree.task === task.id && degree.child_id === childTask.id
          );
    
          if (matchingDegree) {
            // console.log("Matching degree found:", matchingDegree);
    
            // Add file_url from the matching degree to the childTask
            return {
              ...childTask,
              file_url: matchingDegree.file_url,
            };
          }
    
          return childTask;
        }),
      };
    });
    
    // console.log("Updated children tasks:", updatedChildrenTasks);
  }, [isUpdated]);
  useEffect(() => {
    // triggerToast("DATA UPDATED ", "success");
    // if (activeSession) {
    //   fetchTaskDegrees(activeSession.id);
    // }
    // console.log("Initial activeSessionChildrenTasks:", activeSessionChildrenTasks);

    // const newChildrenTasks = activeSessionChildrenTasks?.map((task) => {
    //   console.log("Processing task with ID:", task.id, task);
    
    //   // Filter degrees matching the current task's ID
    //   const taskDegreesForTask = taskDegrees.filter((degree) => degree.task === task.id);
    //   console.log("Matching degrees for task:", taskDegreesForTask);
    
    //   return {
    //     ...task,
    //     children: task.children?.map((child) => {
    //       console.log("Processing child with ID:", child.id);
    
    //       // Find all matching degrees for this child
    //       console.log(taskDegrees);
    //       const childDegrees = taskDegreesForTask.map((degree) =>{
          
    //         if( degree.child_id === child.id){
              
    //           console.log(`Matching degrees for child ${child.id}:`, degree.child_id ,degree);
    //           return degree
    //         }});
    
    //       // Update child's task_completions with the matching degrees
    //       return {
    //         ...child,
    //         task_completations: childDegrees, // Replace with the matched degrees
    //       };
    //     }),
    //   };
    // });
    
    // console.log("Updated newChildrenTasks:", newChildrenTasks);
    
  }, [activeSession]);
  useEffect(() => {
    const live_session = activeSession 
    const live_course = activeCourse
    fetchCourses();
    fetchProblems()
    setIsUpdated(false); // Reset update flag after data is fetched
    // triggerToast("DATA UPDATED ", "success");
    setActiveCourse(live_course)
    setActiveSession(live_session)
  }, [isUpdated]);

  // Create task handler
  const handleCreateTask = async (newTask: { name: string; is_alive: boolean; description: string; sessionId: number }) => {
    try {
      const response = await axios.post("https://codeoceantech.pythonanywhere.com/api/tasks/", {
        title: newTask.name,
        session: activeSession?.id,
        is_alive: newTask.is_alive,
        start_time: new Date().toISOString(),
        description: newTask.description,
      });
      setActiveSessionChildrenTasks((prevTasks = []) => [...prevTasks, response.data]);
      triggerToast("Task created successfully", "success"); // Trigger success toast
      if (activeSession){

        fetchActiveSessionData(activeSession?.id); //
      }
    } catch (error) {
      console.error("Error creating task:", error);
      triggerToast("Error creating task", "error"); // Trigger error toast
    }
  };

  // Toggle session handler
  const handleToggleSession = async (session: Session, courseId: number, is_ended?: boolean) => {
    console.log();
    try {
      const updatedSession = { ...session, is_Started: session.is_Started, is_ended: is_ended || false };
      const response = await axios.put(`https://codeoceantech.pythonanywhere.com/api/sessions/${session.id}/`, {
        ...updatedSession,
        course: courseId,
      });

      setCourses((prevCourses) =>
        prevCourses.map((course) =>
          course.id === courseId
            ? { ...course, sessions: course.sessions.map((s) => (s.id === session.id ? response.data : s)) }
            : course
        )
      );

      if (response.data.is_ended) setActiveSession(response.data);
      else setActiveSession(response.data);
      setIsUpdated(true); // Trigger update after toggling session
      triggerToast(response.data.is_ended ? "Session ended" : "Session started", "info"); // Trigger session status toast
    } catch (error) {
      console.error("Error toggling session:", error);
      triggerToast("Error toggling session", "error"); // Trigger error toast
    }
  };

  // Add child action
  const handleAddChildAction = async (child: number, session_id: number) => {
    try {
      const response = await axios.post(`https://codeoceantech.pythonanywhere.com/api/sessions/${session_id}/attendance/add/`, {
        child_id: child,
      });
      if (response.status === 200) setActiveSessionChildren((prev) => [...prev, child]);
      setIsUpdated(true);
      triggerToast("Child added to session", "success"); // Trigger success toast
      fetchActiveSessionData(session_id)
    } catch (error) {
      console.error("Error adding child to session:", error);
      triggerToast("Error adding child to session", "error"); // Trigger error toast
    }
  };

  // Select course handler
  const handleSelectCourse = (course: Course | null) => {
    setActiveCourse(course);
    setActiveSession(null); // Clear active session when switching courses
  };

  // Select session handler
// Add this function to fetch active session data
const fetchActiveSessionData = async (sessionId: number) => {
  try {
    triggerToast(" updating active session", "warning");
    const response = await axios.get(`https://codeoceantech.pythonanywhere.com/api/sessions/${sessionId}/child-data/`, {
      headers: { Authorization: `Bearer ${token?.access}` },
    });
    handleTokenError();

    // console.log(response.data);

    // Destructure response data
    const { all_in_course, attendance_children, absent_children, tasks } = response.data;
    console.log(response.data);
    console.log(tasks);
    // Update context state with fetched data
    
    setActiveSessionChildren(all_in_course || []);
    setActiveSessionChildrenAttendance(attendance_children || []);
    setActiveSessionChildrenAbsent(absent_children || []);
    
    // Update tasks and mark solved children
    const updatedTasks = tasks.map((task: Task) => ({
      ...task,
      children: task.children?.map((child: any) => ({
        id: child.id,
        name: child.name,
        solved: child.solved,
        degree: child.degree , // Example: Assign a degree if solved
        file_url: child.file_url , // Example: Assign a degree if solved
      })),
    }));

    setActiveSessionChildrenTasks(updatedTasks || []);
    triggerToast("active session updated ", "success");

  } catch (error) {
    // console.error("Error fetching active session data:", error);
    
    triggerToast("Error fetching session data", "error");
      auth?.logout(); // Destructure logout function

    // handleTokenError();
  }
};


// Update handleSelectSession to fetch data for the selected session
const handleSelectSession = (session: Session | null) => {
  if (session) {
    setActiveSession(session);
    fetchActiveSessionData(session.id); // Fetch data for the selected session
  } else {
    setActiveSession(null);
    setActiveSessionChildren([]);
    setActiveSessionChildrenAbsent([]);
    setActiveSessionChildrenAttendance([]);
    setActiveSessionChildrenTasks([]);
  }
};
const handleUpdateTask = async (updatedTask: Task, file: File | null) => {
  try {
    const formData = new FormData();

    // Ensure all fields are valid before appending to FormData
    formData.append('title', updatedTask.title || '');
    formData.append('description', updatedTask.description || '');
    formData.append('is_alive', (updatedTask.is_alive ?? false).toString()); // Default to false if undefined
    // formData.append('start_time', updatedTask.start_time || '');
    // formData.append('duration_days', (updatedTask.duration_days ?? 0).toString()); // Default to 0 if undefined
    // formData.append('duration_minutes', (updatedTask.duration_minutes ?? 0).toString()); // Default to 0 if undefined
    
    // If there is a file, append it to FormData
    if (file) {
      formData.append('file', file);
    }

    const response = await axios.patch(
      `https://codeoceantech.pythonanywhere.com/api/tasks/${updatedTask.id}/`, // API endpoint to update task
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Important to set the correct content type for file uploads
          Authorization: `Bearer ${token?.access}`, // Add the Authorization header
        },
      }
    );

    // Update tasks in the context (state management)
    setActiveSessionChildrenTasks((prevTasks = []) =>
      prevTasks.map((task) =>
        task.id === updatedTask.id ? { ...task, ...response.data } : task
      )
    );
    setIsUpdated(true); // Trigger an update after the task is updated
    triggerToast("Task updated successfully", "success");
  }catch (error: any) {
    console.error("Error updating task:", error);
  
    // Check if 'file' is defined and has a 'last' method
    const errorMessage = error?.file?.[0] || "Error updating task";
  
    triggerToast(errorMessage, "error");
  }
};

  const handleAddChildActionTask = async (child: ChildInTask, taskId: number ,is_solved:boolean, ) => {
    const updatedChild = { ...child, solved: !child.solved };
    const url = `https://codeoceantech.pythonanywhere.com/api/task/${taskId}/${
      !is_solved ? "complete" : "uncomplete"
    }/${child.id}/`;
  
    try {
      const response = await axios.post(
        url,
        { degree: updatedChild.degree },
        { headers: { "Content-Type": "application/json" } }
      );
  
      if (response.status === 200) {
        // Fetch courses or update state after successful task status change
        triggerToast("Task status updated successfully, updating child data!", "warning");

        if (activeSession) {
          fetchActiveSessionData(activeSession.id)
        }
        triggerToast("Task status updated successfully!", "success");
      } else {
        triggerToast("Failed to update task status. Please try again.", "error");
      }
    } catch (error:any) {
      if (axios.isAxiosError(error)) {
        // if (error.response?.status === 401) {
        //   handleTokenError(error);
        // }
        triggerToast(`Error: ${error.response?.data?.message || "An error occurred."}`, "error");
      } else {
        triggerToast("An unexpected error occurred. Please try again later.", "error");
      }
    }
  };
  const handleUpdateTaskDegree = async (taskId: number, childId: number, degree: number) => {
    try {
      const response = await axios.post(`https://codeoceantech.pythonanywhere.com/api/sessions/${taskId}/task/degree/`, {
        degree: degree,
        task_id: taskId,
        child_id: childId,
      });
      triggerToast("Degree updated successfully", "success");
      if (activeSession) {
        
        fetchActiveSessionData(activeSession.id)
      }
    } catch (error) {
      triggerToast("Error updating degree", "error");

    }
  };

  const handleUploadFile = async (taskId: number, file: File ,child_id:string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("child_id", child_id);
    triggerToast("جاري رفع الفيل", "info");

    try {
      await axios.post(
        `https://codeoceantech.pythonanywhere.com/api/tasks/${taskId}/upload/`,
        formData,
        {
          headers: { Authorization: `Bearer ${token?.access}` },
        }
      );
      triggerToast("تم رفع الفيل بنجاح", "success");
    } catch (error) {
      alert("فشل رفع الملف.");
    } finally {
      if (activeSession) {
        
        fetchActiveSessionData(activeSession.id)
      }
    }
  };
  return (
    <CourseContext.Provider
      value={{
        courses,
        problems,
        activeCourse,
        activeSession,
        activeSessionChildren,
        activeSessionChildrenAbsent,
        activeSessionChildrenAttendance,
        activeSessionChildrenTasks,
        taskDegrees,
        fetchProblems,
        fetchCourses,
        fetchTaskDegrees,
        postProblem,
        handleSelectCourse,
        handleSelectSession,
        handleToggleSession,
        handleCreateTask,
        handleUpdateTask,
        handleAddChildAction,
        handleAddChildActionTask,
        handleUpdateTaskDegree,
        handleUploadFile
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export { CourseContext, CourseProvider };
