import React, { useState, useContext, useEffect } from "react";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { app } from "../../components/Common/firebaseConfig";
import { AuthContext } from "../../context/AuthContext";
import useNotification from "../../context/useNotification";
import { Switch, FormControlLabel, IconButton } from "@mui/material";
import { Notifications, Login, CheckCircle, Error } from "@mui/icons-material";

const NotificationSettings: React.FC = () => {
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(false);
    const [googleLoginEnabled, setGoogleLoginEnabled] = useState<boolean>(false);
    const authContext = useContext(AuthContext); // Access authentication context
    const id = authContext?.user?.user_info.user_id || 0; // Extract user ID from the URL
  const triggerToast = useNotification(); // Initialize the notification hook

  // Function to get browser and device information
  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown Browser";
    let version = "Unknown Version";
    
    if (userAgent.includes("Chrome")) {
      browserName = "Google Chrome";
      version = userAgent.split("Chrome/")[1].split(" ")[0];
    } else if (userAgent.includes("Firefox")) {
      browserName = "Mozilla Firefox";
      version = userAgent.split("Firefox/")[1];
    } else if (userAgent.includes("Safari")) {
      browserName = "Safari";
      version = userAgent.split("Version/")[1].split(" ")[0];
    }
    // Add more checks for other browsers if needed

    return { browserName, version };
  };

  // Function to check if the device is iOS
  const isIOS = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent);
  };

  // Check notification permission on mount
  useEffect(() => {
    if ("Notification" in window) {
      const permission = Notification.permission;
      if (permission === "granted") {
        setNotificationsEnabled(true); // Set the notifications toggle to checked
      } else if (isIOS()) {
        // If it's iOS and notifications are not granted, request permission
        requestNotificationPermission();
      }
    }

    // Check if the user is already logged in and has an email
    const user = authContext?.user; // Assuming you store the user's info in the context
    if (user && user.user_info.email) {
      setGoogleLoginEnabled(true); // Automatically check the Google login toggle
    }
  }, [authContext]);

  // Request notification permissions from the user
  const requestNotificationPermission = async () => {
    if ("Notification" in window) {
      try {
        triggerToast("Notifications are about to be enabled! You’ll receive updates here.", "info");
        const permission = await Notification.requestPermission();
  
        if (permission === "granted") {
          triggerToast("Notifications enabled! You’ll receive updates here.", "success");
          setNotificationsEnabled(true); // Enable notifications toggle
  
          // Send a welcome notification
          const notificationOptions = {
            body: "Thank you for enabling notifications. We're excited to have you onboard!",
            icon: "/logo192.png", // Replace with your logo or any icon
          };
          new Notification("Elmohandes Academy", notificationOptions);
        } else {
          const { browserName, version } = getBrowserInfo();
          let message = `Notifications permission denied. Please enable them to receive updates.`;
  
          if (browserName === "Google Chrome") {
            message = `To enable, go to Settings > Privacy and Security > Site Settings > Notifications.`;
          } else if (browserName === "Mozilla Firefox") {
            message = `To enable, go to Options > Privacy & Security > Permissions > Notifications.`;
          } else if (browserName === "Safari") {
            message = `To enable, go to Safari Preferences > Websites > Notifications.`;
          }
  
          triggerToast(message, "warning");
        }
      } catch (error) {
        console.error("Error requesting notification permission:", error);
        triggerToast("Error requesting notification permission.", "error");
      }
    } else {
      triggerToast("This browser does not support notifications.", "info");
    }
  };
  
  // Handle Google login for the user
  const handleGoogleLogin = async () => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (!user) {
        triggerToast("Google login failed. Please try again.", "error");
        return;
      }

      // Display success toast notification
      triggerToast(`Welcome, ${user.displayName || "User"}! Login successful.`, "success");

      // Update user profile using AuthContext
      if (authContext && id) {
        const formDataWithImage = new FormData();
        formDataWithImage.append("username", authContext?.user?.user_info.username || "");
        formDataWithImage.append("email", user.email || " ");
        formDataWithImage.append("phone_number", user.phoneNumber || "");

        try {
          const response = await authContext.updateUser(
            id,
            formDataWithImage,
            (progress) => setUploadProgress(progress)
          );

          console.log("User updated successfully:", response);
          triggerToast("Profile updated successfully after login.", "success");
        } catch (error) {
          console.error("Error updating user profile:", error);
          triggerToast("Failed to update profile after login.", "error");
        }
      }
    } catch (error: any) {
      console.error("Google login failed:", error.message);
      triggerToast("Google login failed. Please try again.", "error");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold text-gray-800 text-center mb-6">
          Notification Settings
        </h2>

        {/* Google Login Toggle */}
        <div className="flex items-center justify-between mb-4">
          <FormControlLabel
            control={
              <Switch
                checked={googleLoginEnabled}
                onChange={() => {
                  setGoogleLoginEnabled(!googleLoginEnabled);
                  if (!googleLoginEnabled) {
                    handleGoogleLogin(); // Trigger Google login
                  }
                }}
                color="primary"
                icon={<Login />}
                checkedIcon={<CheckCircle />}
              />
            }
            label="Enable Google Login"
          />
        </div>

        {/* Notification Permission Toggle */}
        <div className="flex items-center justify-between mb-4">
          <FormControlLabel
            control={
              <Switch
                checked={notificationsEnabled}
                onChange={() => {
                  setNotificationsEnabled(!notificationsEnabled);
                  if (!notificationsEnabled) {
                    requestNotificationPermission(); // Request notification permission
                  }
                }}
                color="secondary"
                icon={<Notifications />}
                checkedIcon={<CheckCircle />}
              />
            }
            label="Enable Notifications"
          />
        </div>

        {/* Upload Progress */}
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="mt-4">
            <div className="text-sm text-gray-600 mb-2">
              Uploading profile image: {uploadProgress}%
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationSettings;
